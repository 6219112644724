<template>
  <div>
    <el-col>
      <template>
        You can apply Filters on entity data by selecting entity
        field.
      </template>
      <el-button @click="addFilter" type="text">Add Filter+</el-button>
      <br />
      <div
        style="display: flex !important;"
        v-if="
          formbuilderFiltersData &&
          formbuilderFiltersData._id
        "
      >
        <p v-if="formbuilderFiltersData.filter_name">
          Selected Filter:
          <span style="font-weight: 600;">{{ formbuilderFiltersData.filter_name }}</span>
        </p>
        <el-tooltip class="item" effect="dark" content="Delete filter" placement="top-start">
          <el-link class="mb-1 ml-1" type="danger" :underline="false" @click="deleteFilter">
            <i class="el-icon-delete"></i>
          </el-link>
        </el-tooltip>
      </div>
      <el-divider></el-divider>

      <div
        class="mt-2"
        v-if="
          formbuilderFiltersData &&
          formbuilderFiltersData.filters &&
          formbuilderFiltersData.filters.length
        "
      >
        Query type
        <el-radio-group v-model="formbuilderFiltersData.query_type">
          <el-radio label="AND"></el-radio>
          <el-radio label="OR"></el-radio>
        </el-radio-group>
      </div>
      <div
        v-else-if="!this.$route.query.routeType"
      >There is no filter selected. Please click on Add Filter.</div>
      <br />
      <el-scrollbar wrap-style="max-height: 250px;" ref="filterScroll">
        <table v-if="formbuilderFiltersData.filters">
          <tr
            v-for="(filter, index) in formbuilderFiltersData.filters"
            :key="index + '-' + filter.field"
          >
            <td>
              <tr>
                <td>
                  <el-tooltip effect="dark" content="Select a field" placement="top-start">
                    <el-select
                      v-model="filter.field"
                      placeholder="Select Field"
                      clearable
                      filterable
                      size="mini"
                      @change="
                        (changedValue) =>
                          checkCurrentFieldData(changedValue, filter)
                      "
                    >
                      <el-option
                        v-for="(field, index) of allFormbuilderFields.filter(e => e.input_type !== 'DATA_TABLE' && e.input_type !== 'ENTITY_TABLE')"
                        :key="index"
                        :value="field.key"
                        :label="field.label"
                      >{{ field.label }}</el-option>
                    </el-select>
                  </el-tooltip>
                </td>
                <td>
                  <el-tooltip effect="dark" content="Select operator" placement="top-start">
                    <el-select
                      v-model="filter.operator"
                      size="mini"
                      :disabled="!filter.field"
                      placeholder="Operator"
                    >
                      <el-option
                        v-for="(operator, index) of getCurrentFieldOperators(
                          filter
                        )"
                        :key="index"
                        :label="operator.title"
                        :value="operator.value"
                      ></el-option>
                      <el-option
                        v-if="
                          filter.input_type == 'SELECT' &&
                          filter.data_type == 'ARRAY'
                        "
                        value="=text-includes"
                        label="Text Includes"
                      ></el-option>
                    </el-select>
                  </el-tooltip>
                </td>
                <td
                  v-if="
                    filter.operator != 'exists' &&
                    filter.operator != 'notexists' &&
                    filter.operator != 'between' &&
                    filter.operator != 'notbetween' &&
                    filter.operator != 'dateinclude' &&
                    filter.operator != 'notdateinclude' &&
                    filter.operator != 'real_time' &&
                    filter.operator != 'weekdays' &&
                    filter.operator != 'filter' &&
                    filter.data_type !== 'STATUS'
                  "
                >
                  <el-tooltip effect="dark" content="Select value type" placement="top-start">
                    <el-select
                      v-if="
                        filter.operator != 'lastmonth' &&
                        filter.operator != 'thismonth'
                      "
                      v-model="filter.data_source"
                      @change="(value) => checkSourceDataChange(value, filter)"
                      size="mini"
                      placeholder="Value type"
                      :disabled="!filter.operator"
                    >
                      <el-option
                        v-for="(source, index) of allowedDataSources(filter)"
                        :key="index"
                        :label="source"
                        :value="source"
                      ></el-option>
                      <el-option
                        value="TEMPLATE"
                        label="Template"
                        v-if="
                          checkTemplateCondition(filter) &&
                          filter.data_type != 'created_by' &&
                          filter.data_type != 'updated_by' &&
                          filter.data_type != 'created_at' &&
                          filter.data_type != 'updated_at' &&
                          filter.input_type != 'SELECT'
                        "
                      ></el-option>
                      <el-option
                        value="LOGIN_USER"
                        label="Login User"
                        v-if="
                          filter.data_type == 'created_by' ||
                          filter.data_type == 'updated_by'
                        "
                      ></el-option>
                      <el-option
                        v-if="filter.data_type == 'WEEKDAYS'"
                        value="weekdays"
                        label="Weekdays"
                      ></el-option>
                      <el-option
                        v-if="checkIsSelect(filter)"
                        value="field_option"
                        label="From field"
                      ></el-option>
                      <el-option
                        v-if="
                          filter.data_type == 'STRING' &&
                          allowedLoginFilterOp.indexOf(filter.operator) != -1
                        "
                        value="login_user"
                        label="FROM LOGIN USER"
                      ></el-option>
                      <el-option
                        v-if="
                          filter.input_type == 'SELECT' &&
                          filter.data_type == 'ARRAY' &&
                          filter.operator == '=text-includes'
                        "
                        value="VALUE"
                        label="Value"
                      ></el-option>
                    </el-select>
                  </el-tooltip>
                </td>
                <td
                  v-if="
                    filter.operator != 'exists' &&
                    filter.operator != 'notexists'
                  "
                >
                  <div>
                    <el-select
                      v-if="filter.data_source == 'TEMPLATE'"
                      v-model="filter.value_field"
                      size="mini"
                      placeholder="Template fields"
                    >
                      <el-option
                        v-for="(
                          enityData, index
                        ) of getSelfTemplateFieldsByType(filter)"
                        :key="index"
                        :label="enityData.label"
                        :value="enityData.key"
                      ></el-option>
                    </el-select>
                    <el-select
                      v-model="filter.value"
                      v-if="
                        filter.data_type == 'BOOLEAN' &&
                        filter.data_source != 'TEMPLATE'
                      "
                      size="mini"
                    >
                      <el-option :value="true" label="Checked"></el-option>
                      <el-option :value="false" label="Unchecked"></el-option>
                    </el-select>
                    <el-select
                      v-model="filter.value"
                      v-if="
                        filter.data_type == 'DATE' &&
                        filter.data_source == 'CURRENT_DATE'
                      "
                      size="mini"
                    >
                      <el-option value="today" label="Today"></el-option>
                    </el-select>

                    <el-select
                      v-model="filter.value"
                      v-if="
                        filter.data_type == 'YES_OR_NO' &&
                        filter.data_source != 'TEMPLATE'
                      "
                      size="mini"
                    >
                      <el-option value="YES" label="Yes"></el-option>
                      <el-option value="NO" label="No"></el-option>
                    </el-select>
                    <el-select
                      v-model="filter.value"
                      v-if="
                        filter.data_type == 'DATE' &&
                        filter.operator == 'weekdays'
                      "
                      size="mini"
                      multiple
                      collapse-tags
                    >
                      <el-option
                        v-for="(option, index) of weekdays_options"
                        :value="option.value"
                        :label="option.name"
                        :key="index"
                      ></el-option>
                    </el-select>
                    <el-select
                      v-model="filter.value"
                      v-if="
                        filter.data_source == 'weekdays' &&
                        filter.data_type == 'WEEKDAYS'
                      "
                      size="mini"
                      multiple
                      collapse-tags
                    >
                      <el-option
                        v-for="(option, index) of weekdays_options"
                        :value="option.key"
                        :label="option.name"
                        :key="index"
                      ></el-option>
                    </el-select>
                    <el-select
                      v-model="filter.relational_filter_query"
                      v-if="
                        filter.data_type == 'RELATION' &&
                        filter.operator == 'filter'
                      "
                      :disabled="!checkIsFirstRelationalField(index)"
                      @change="setRelationalFilterQuery"
                      size="mini"
                    >
                      <el-option
                        v-for="(item, index) in [
                          { label: 'Includes', value: 'includes' },
                          { label: 'Not includes', value: 'not_includes' },
                        ]"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>

                    <el-select
                      v-model="filter.value_field"
                      size="mini"
                      v-if="filter.data_source == 'login_user'"
                      @change="
                        (changedValue) =>
                          checkCurrentFieldValue(changedValue, filter)
                      "
                    >
                      <el-option
                        v-for="(op, index) in loginUserFields"
                        :key="index + op.value"
                        :label="op.label"
                        :value="op.value"
                      >{{ op.label }}</el-option>
                    </el-select>
                    <el-input
                      v-model="filter.value"
                      v-if="
                        filter.data_source == 'VALUE' &&
                        (filter.data_type == 'STRING' ||
                          (filter.input_type == 'SELECT' &&
                            filter.data_type == 'ARRAY' &&
                            filter.operator == '=text-includes'))
                      "
                      size="mini"
                    ></el-input>

                    <div
                      v-if="
                        (filter.data_type == 'NUMBER' ||
                          filter.data_type == 'CURRENCY') &&
                        filter.operator == 'between'
                      "
                    >
                      <el-input-number
                        v-model="filter.range1"
                        :controls="false"
                        size="mini"
                        placeholder="starting value"
                        :min="0"
                        style="margin-right: 15px"
                      ></el-input-number>
                      <span>To</span>
                      <el-input-number
                        v-model="filter.range2"
                        :controls="false"
                        size="mini"
                        :min="0"
                        placeholder="ending value"
                        style="margin-left: 15px"
                      ></el-input-number>
                    </div>
                    <!-- <el-input-number
                        v-model="filter.value"
                        v-if="
                          filter.data_type == 'DATE' &&
                          (filter.operator == 'after' ||
                            filter.operator == 'before')
                        "
                        :controls="false"
                        size="mini"
                    ></el-input-number>-->
                    <div
                      v-if="
                        filter.data_type == 'DATE' &&
                        (filter.operator == 'after' ||
                          filter.operator == 'before') &&
                        filter.data_source == 'VALUE'
                      "
                      class="counter-box"
                    >
                      <el-input-number
                        v-model="filter.value"
                        size="mini"
                        :min="1"
                        style="width: 100px"
                      ></el-input-number>
                      <el-select
                        v-model="filter.units"
                        size="mini"
                        style="width: 100px; margin-left: 4px"
                      >
                        <el-option value="days" label="Day(s)"></el-option>
                        <el-option value="weeks" label="Week(s)"></el-option>
                        <el-option value="months" label="Month(s)"></el-option>
                        <el-option value="years" label="Year(s)"></el-option>
                      </el-select>
                    </div>
                    <el-input-number
                      v-model="filter.value"
                      v-if="
                        filter.data_source == 'VALUE' &&
                        filter.data_type == 'NUMBER' &&
                        filter.operator != 'between'
                      "
                      :controls="false"
                      size="mini"
                    ></el-input-number>
                    <el-input-number
                      v-model="filter.value"
                      v-if="
                        filter.data_source == 'VALUE' &&
                        filter.data_type == 'CURRENCY'
                      "
                      :controls="false"
                      size="mini"
                    ></el-input-number>
                    <el-date-picker
                      v-model="filter.value"
                      size="mini"
                      placeholder="Select Date"
                      v-if="
                        filter.data_source == 'VALUE' &&
                        filter.data_type == 'DATE' &&
                        filter.operator != 'real_time' &&
                        filter.operator != 'between' &&
                        filter.operator != 'weekdays' &&
                        filter.operator != 'after' &&
                        filter.operator != 'before'
                      "
                      :format="
                        getDefaultDateFormat &&
                        getDefaultDateFormat.includes('Do') == false
                          ? getDefaultDateFormat
                              .replace('YYYY', 'yyyy')
                              .replace('DD', 'dd')
                          : 'MM-dd-yyyy'
                      "
                    ></el-date-picker>
                    <el-time-picker
                      v-model="filter.value"
                      size="mini"
                      placeholder="select time"
                      v-if="
                        filter.data_type == 'TIME_RANGE' &&
                        filter.operator == 'timeinclude'
                      "
                      :picker-options="{
                        format: 'HH:mm:ss',
                      }"
                      :value-format="'HH:mm:ss'"
                    ></el-time-picker>
                    <el-date-picker
                      v-model="filter.value"
                      placeholder="Select Date"
                      size="mini"
                      v-if="
                        filter.data_source == 'VALUE' &&
                        filter.data_type == 'DATE_TIME' &&
                        filter.operator != 'real_time' &&
                        filter.operator != 'between' &&
                        filter.operator != 'dateinclude' &&
                        filter.operator != 'notdateinclude' &&
                        filter.operator != 'weekdays'
                      "
                      :format="
                        getDefaultDateFormat &&
                        getDefaultDateFormat.includes('Do') == false
                          ? getDefaultDateFormat
                              .replace('YYYY', 'yyyy')
                              .replace('DD', 'dd')
                          : 'MM-dd-yyyy'
                      "
                    ></el-date-picker>
                    <el-date-picker
                      v-model="filter.value"
                      size="mini"
                      v-else-if="
                        filter.data_source == 'VALUE' &&
                        filter.data_type == 'created_at'
                      "
                      :format="
                        getDefaultDateFormat &&
                        getDefaultDateFormat.includes('Do') == false
                          ? getDefaultDateFormat
                              .replace('YYYY', 'yyyy')
                              .replace('DD', 'dd')
                          : 'MM-dd-yyyy'
                      "
                    ></el-date-picker>
                    <el-select
                      v-if="
                        filter.input_type === 'created_by' &&
                        filter.data_source === 'VALUE'
                      "
                      v-model="filter.value"
                      size="mini"
                      multiple
                      collapse-tags
                      style="margin-left: 10px"
                    >
                      <el-option
                        v-for="(user, index) in users.data"
                        :key="index"
                        :label="user.email"
                        :value="user._id"
                      ></el-option>
                    </el-select>
                    <el-select
                      v-if="
                        filter.input_type === 'updated_by' &&
                        filter.data_source === 'VALUE'
                      "
                      v-model="filter.value"
                      size="mini"
                      multiple
                      collapse-tags
                      style="margin-left: 10px"
                    >
                      <el-option
                        v-for="(user, index) in users.data"
                        :key="index"
                        :label="user.email"
                        :value="user._id"
                      ></el-option>
                    </el-select>
                    <el-date-picker
                      v-model="filter.value"
                      size="mini"
                      v-else-if="
                        filter.data_source == 'VALUE' &&
                        filter.data_type == 'updated_at'
                      "
                      :format="
                        getDefaultDateFormat &&
                        getDefaultDateFormat.includes('Do') == false
                          ? getDefaultDateFormat
                              .replace('YYYY', 'yyyy')
                              .replace('DD', 'dd')
                          : 'MM-dd-yyyy'
                      "
                    ></el-date-picker>
                    <el-date-picker
                      v-model="filter.value"
                      size="mini"
                      v-if="
                        (filter.data_type == 'DATE_TIME' ||
                          filter.data_type == 'DATE_RANGE') &&
                        filter.operator == 'dateinclude'
                      "
                      :format="
                        getDefaultDateFormat &&
                        getDefaultDateFormat.includes('Do') == false
                          ? getDefaultDateFormat
                              .replace('YYYY', 'yyyy')
                              .replace('DD', 'dd')
                          : 'MM-dd-yyyy'
                      "
                    ></el-date-picker>
                    <el-date-picker
                      v-model="filter.value"
                      size="mini"
                      v-if="
                        (filter.data_type == 'DATE_TIME' ||
                          filter.data_type == 'DATE') &&
                        filter.operator == 'notdateinclude'
                      "
                      :format="
                        getDefaultDateFormat &&
                        getDefaultDateFormat.includes('Do') == false
                          ? getDefaultDateFormat
                              .replace('YYYY', 'yyyy')
                              .replace('DD', 'dd')
                          : 'MM-dd-yyyy'
                      "
                    ></el-date-picker>
                    <el-select
                      v-if="
                        filter.data_source == 'REAL_TIME_PICKER' &&
                        filter.data_type == 'DATE_TIME' &&
                        filter.operator != 'real_time' &&
                        filter.operator != 'between' &&
                        filter.operator != 'weekdays'
                      "
                      placeholder="Pick date range"
                      v-model="filter.selected_date_picker"
                      size="mini"
                      @change="
                        (changedValue) =>
                          setDatePickerDate(changedValue, filter)
                      "
                    >
                      <el-option label="Today" value="today">Today</el-option>
                      <el-option label="Yesterday" value="yesterday">Yesterday</el-option>
                    </el-select>
                    <CustomDatePicker
                      v-if="
                        filter.data_type == 'DATE' &&
                        filter.operator == 'real_time'
                      "
                      :field="filter"
                    ></CustomDatePicker>
                    <el-date-picker
                      v-if="
                        filter.data_type == 'DATE' &&
                        filter.operator == 'between'
                      "
                      v-model="filter.value"
                      size="mini"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                    ></el-date-picker>
                    <el-date-picker
                      v-if="
                        filter.data_type == 'DATE' &&
                        filter.operator == 'notbetween'
                      "
                      v-model="filter.value"
                      size="mini"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                    ></el-date-picker>

                    <el-time-picker
                      is-range
                      v-if="
                        filter.data_type == 'TIME' &&
                        filter.operator == 'between' &&
                        isFixed(filter) === false
                      "
                      v-model="filter.value"
                      size="mini"
                      range-separator="To"
                      start-placeholder="Start time"
                      end-placeholder="End time"
                      :picker-options="{
                        format: 'HH:mm:ss',
                      }"
                      :value-format="'HH:mm:ss'"
                    ></el-time-picker>
                    <el-time-picker
                      is-range
                      v-if="
                        filter.data_type == 'TIME' &&
                        filter.operator == 'notbetween' &&
                        isFixed(filter) === false
                      "
                      v-model="filter.value"
                      size="mini"
                      range-separator="To"
                      start-placeholder="Start Time"
                      end-placeholder="End Time"
                      :picker-options="{
                        format: 'HH:mm:ss',
                      }"
                      :value-format="'HH:mm:ss'"
                    ></el-time-picker>
                    <el-time-picker
                      is-range
                      v-if="
                        filter.data_type == 'TIME' &&
                        filter.operator == 'between' &&
                        isFixed(filter) === true
                      "
                      v-model="filter.value"
                      size="mini"
                      range-separator="To"
                      start-placeholder="Start Time"
                      end-placeholder="End Time"
                      :picker-options="{
                        format: 'HH:mm:ss',
                      }"
                      :value-format="'HH:mm'"
                    ></el-time-picker>
                    <el-time-picker
                      is-range
                      v-if="
                        filter.data_type == 'TIME' &&
                        filter.operator == 'notbetween' &&
                        isFixed(filter) === true
                      "
                      v-model="filter.value"
                      size="mini"
                      range-separator="To"
                      start-placeholder="Start time"
                      end-placeholder="End time"
                      :picker-options="{
                        format: 'HH:mm:ss',
                      }"
                      :value-format="'HH:mm'"
                    ></el-time-picker>

                    <el-time-picker
                      v-model="filter.value"
                      size="mini"
                      placeholder="Select Time"
                      v-if="
                        filter.data_source == 'VALUE' &&
                        filter.data_type == 'TIME' &&
                        isFixed(filter) === false &&
                        filter.operator != 'between'
                      "
                      :picker-options="{
                        format: 'HH:mm:ss',
                      }"
                      :value-format="'HH:mm:ss'"
                    ></el-time-picker>

                    <el-time-select
                      v-model="filter.value"
                      v-if="
                        filter.data_source == 'VALUE' &&
                        filter.data_type == 'TIME' &&
                        isFixed(filter) === true &&
                        filter.operator != 'between'
                      "
                      placeholder="Select time"
                      size="mini"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59',
                      }"
                    ></el-time-select>

                    <el-select
                      v-model="filter.global_variable_id"
                      v-if="filter.data_source == 'GLOBAL_VARIABLE'"
                      no-data-text="No Global Varibles with Field"
                      size="mini"
                      @change="(value) => addGlobalVaraibleData(value, filter)"
                    >
                      <el-option
                        v-for="(gbVab, index) of selectedGlobalVariablesList(
                          filter
                        )"
                        :key="index"
                        :label="`${gbVab.label} -> ${
                          gbVab.value ? gbVab.value : ''
                        }`"
                        :value="gbVab._id"
                      ></el-option>
                    </el-select>

                    <el-select
                      v-if="
                        filter.data_source == 'NEW_LIST' &&
                        filter.operator != 'real_time' &&
                        filter.operator != 'weekdays'
                      "
                      placeholder="Enter values"
                      v-model="filter.value"
                      :clearable="true"
                      :allow-create="true"
                      :default-first-option="true"
                      :filterable="true"
                      :multiple="true"
                      :no-data-text="''"
                      @change="(data) => parseOptionsAsNumbers(data, filter)"
                      size="mini"
                    />
                    <el-select
                      v-model="filter.value"
                      v-if="filter.data_source == 'ENTITY_DATA_LIST'"
                      multiple
                      collapse-tags
                      size="mini"
                      filterable
                    >
                      <el-option
                        v-for="(temp, i) in entilistAsOptions(filter)"
                        :key="temp._id + '_' + index + '_' + i"
                        :value="temp._id"
                        :label="getLabel(temp, i)"
                      >{{ getLabel(temp, i) }}</el-option>
                    </el-select>

                    <el-input
                      v-if="
                        (filter.data_source == '' ||
                          filter.data_source == undefined) &&
                        filter.operator != 'between' &&
                        filter.operator != 'notbetween' &&
                        filter.operator != 'dateinclude' &&
                        filter.operator != 'notdateinclude' &&
                        filter.operator != 'real_time' &&
                        filter.operator != 'weekdays' &&
                        filter.operator == 'Equals'
                      "
                      v-model="filter.value"
                      size="mini"
                      :disabled="true"
                    ></el-input>

                    <el-select
                      v-model="filter.value_field"
                      v-if="filter.data_source == 'self_field'"
                      collapse-tags
                      size="mini"
                    >
                      <el-option
                        v-for="(enityData, index) of getSelfTemplateFields(
                          filter
                        )"
                        :key="index"
                        :label="enityData.label"
                        :value="enityData.key"
                      ></el-option>
                    </el-select>
                    <el-select
                      v-model="filter.value"
                      v-if="filter.data_source == 'field_option'"
                      collapse-tags
                      size="mini"
                      :multiple="
                        filter.operator == 'in' || filter.operator == 'nin'
                          ? true
                          : false
                      "
                    >
                      <template v-if="fieldType(filter, 'LIST')">
                        <el-option
                          v-for="(enityData, index) of getCurrentFieldOptions(
                            filter
                          )"
                          :key="index + '_' + enityData.value"
                          :label="enityData.name"
                          :value="enityData.value"
                          multiple
                          collapse-tags
                        ></el-option>
                      </template>
                      <template v-else-if="fieldType(filter, 'WEEKDAYS')">
                        <el-option
                          v-for="(option, index) of weekdays_options"
                          :value="option.key"
                          :label="option.name"
                          :key="index"
                        ></el-option>
                      </template>
                      <template v-else>
                        <el-option
                          v-for="(enityData, index) of getCurrentFieldOptions(
                            filter
                          )"
                          :key="index"
                          :label="enityData"
                          :value="enityData"
                          multiple
                          collapse-tags
                        ></el-option>
                      </template>
                    </el-select>
                  </div>
                </td>
                <td>
                  <el-button type="text" size="mini" @click="removeFilterFromList(index)">Remove</el-button>
                </td>
              </tr>
            </td>
          </tr>
        </table>
      </el-scrollbar>
      <!-- <span class="warning">
        Note: The quick filters of the first
        <em>Relation-with #Entity</em> Filter will be displayed alongside the
        current filter's quick filters.
      </span>-->
      <el-divider></el-divider>
      <div
        v-if="
          (formbuilderFiltersData.isSaveFilter || formbuilderFiltersData._id)
        "
        class="mt-1 d-flex"
      >
        <div
          v-if="
            (formbuilderFiltersData.isSaveFilter || formbuilderFiltersData._id)
          "
        >
          <div style="margin-bottom: 10px">
            Filter name
            <span class="text-danger">*</span>
          </div>
          <el-input
            v-model="formbuilderFiltersData.filter_name"
            placeholder="Enter filter name"
            size="mini"
          ></el-input>
        </div>
      </div>
      <el-checkbox
        class="mt-1"
        v-model="formbuilderFiltersData.isSaveFilter"
      >Save this filter for future use ?</el-checkbox>
      <el-checkbox
        class="mt-1"
        v-model="formbuilderFiltersData.updateFilter"
        v-if="formbuilderFiltersData._id"
      >Update this filter?</el-checkbox>
    </el-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { postAPICall } from "../../helpers/httpHelper";
export default {
  components: {
    CustomDatePicker: () => import("@/components/widgets/customDatePicker.vue")
  },
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    entilistAsOptions() {
      return filter => {
        let field = this.allFormbuilderFields.find(e => e.key == filter.field);
        if (
          field &&
          field.entity_id &&
          this.entityDataInfo &&
          this.entityDataInfo.length
        ) {
          let selectedEntity = this.entityDataInfo.find(
            e => e.entity_id === field.entity_id
          );
          if (selectedEntity && selectedEntity.data) {
            return selectedEntity.data;
          }
        } else {
          return [];
        }
      };
    }
  },
  data() {
    return {
      weekdays_options: [
        { name: "Monday", value: 1, key: "MON" },
        { name: "Tuesday", value: 2, key: "TUE" },
        { name: "Wednesday", value: 3, key: "WED" },
        { name: "Thursday", value: 4, key: "THU" },
        { name: "Friday", value: 5, key: "FRI" },
        { name: "Saturday", value: 6, key: "SAT" },
        { name: "Sunday", value: 0, key: "SUN" }
      ],
      users: [],
      starRating_options: [
        { name: "1", value: 1 },
        { name: "1.5", value: 1.5 },
        { name: "2", value: 2 },
        { name: "2.5", value: 2.5 },
        { name: "3", value: 3 },
        { name: "3.5", value: 3.5 },
        { name: "4", value: 4 },
        { name: "4.5", value: 4.5 },
        { name: "5", value: 5 }
      ],
      everyYearFilters: ["this_year", "last_year"],
      allowedLoginFilterOp: [
        "=text-includes",
        "=case-insensitive",
        "=case-sensitive"
      ],
      default_relational_filter_query: "includes",

      newFilterData: {
        field: "",
        entity_id: "",
        template_id: "",
        operator: "",
        value: "",
        value_type: "",
        value_source: "",
        data_type: "",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: ""
        },
        relation_entity_id: "",
        relational_filter_query: "includes",
        isUserFilter: false
      },
      query_type: "AND",
      operatorsMap: {
        NUMBER: [
          {
            title: "Equals (=)",
            value: "="
          },
          {
            title: "Greater Than (>)",
            value: ">"
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">="
          },
          {
            title: "Less Than (<)",
            value: "<"
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<="
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Range",
            value: "between"
          }
        ],
        PHONE: [
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          }
        ],
        CONCATENATE: [
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          }
        ],
        STRING: [
          {
            title: "Equals (Case-sensitive)",
            value: "=case-sensitive"
          },
          {
            title: "Equals (Case-insensitive)",
            value: "=case-insensitive"
          },
          {
            title: "Text Includes",
            value: "=text-includes"
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Starts With",
            value: "=starts-with"
          },
          {
            title: " Not Starts With",
            value: "=not-starts-with"
          },
          {
            title: "Ends With",
            value: "=ends-with"
          },
          {
            title: "Not Ends With",
            value: "!ends-with"
          }
        ],
        PAYMENT: [
          {
            title: "In",
            value: "in"
          },
          {
            title: "Not In",
            value: "nin"
          },
          {
            title: "Equals (Case-sensitive)",
            value: "=case-sensitive"
          },
          {
            title: " Not Equals (Case-sensitive)",
            value: "!=case-sensitive"
          },
          {
            title: "Equals (Case-insensitive)",
            value: "=case-insensitive"
          },
          {
            title: " Not Equals (Case-insensitive)",
            value: "!=case-insensitive"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          }
        ],
        OBJECT_ID: [
          {
            title: "In",
            value: "in"
          },
          {
            title: "Not In",
            value: "nin"
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Exist",
            value: "exists"
          }
        ],
        DATE: [
          {
            title: "Real time picker",
            value: "real_time"
          },
          {
            title: "Match with weekdays",
            value: "weekdays"
          },
          {
            title: "Equals (=)",
            value: "="
          },
          {
            title: "Greater Than (>)",
            value: ">"
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">="
          },
          {
            title: "Less Than (<)",
            value: "<"
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<="
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Between",
            value: "between"
          },
          {
            title: "NotBetween",
            value: "notbetween"
          },
          {
            title: "Before",
            value: "before"
          },
          {
            title: "After",
            value: "after"
          }
        ],
        TIME: [
          {
            title: "Equals (=)",
            value: "=text-includes"
          },
          {
            title: "Greater Than (>)",
            value: ">"
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">="
          },
          {
            title: "Less Than (<)",
            value: "<"
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<="
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Between",
            value: "between"
          },
          {
            title: "NotBetween",
            value: "notbetween"
          }
        ],
        RELATION: [
          {
            title: "In",
            value: "in"
          },
          {
            title: "Not In",
            value: "nin"
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Filter",
            value: "filter"
          }
        ],
        FILE: [
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Exist",
            value: "exists"
          }
        ],
        ARRAY: [
          {
            title: "In",
            value: "in"
          },
          {
            title: "Not In",
            value: "nin"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          }
        ],
        DATE_TIME: [
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Date Includes",
            value: "dateinclude"
          },
          {
            title: "Date Not Includes",
            value: "notdateinclude"
          }
        ],
        WEEKDAYS: [
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "In",
            value: "in"
          },
          {
            title: "Not In",
            value: "nin"
          }
        ],
        TIME_RANGE: [
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Time includes",
            value: "timeinclude"
          }
        ],
        YES_OR_NO: [
          {
            title: "Equals",
            value: "=case-sensitive"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          }
        ],
        CURRENCY: [
          {
            title: "Equals (=)",
            value: "="
          },
          {
            title: "Greater Than (>)",
            value: ">"
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">="
          },
          {
            title: "Less Than (<)",
            value: "<"
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<="
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Range",
            value: "between"
          }
        ],
        BOOLEAN: [
          {
            title: "In",
            value: "in"
          },
          {
            title: "Not In",
            value: "nin"
          },
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          }
        ],
        DATE_RANGE: [
          {
            title: "Exist",
            value: "exists"
          },
          {
            title: "Not Exist",
            value: "notexists"
          },
          {
            title: "Date includes",
            value: "dateinclude"
          }
        ]
      },

      operatorValueTypeMap: [
        {
          value_type: "VALUE",
          operators: [
            "=",
            "<=",
            "<",
            ">",
            ">=",
            "=case-insensitive",
            "=case-sensitive",
            "=text-includes",
            "!=",
            "dateinclude",
            "timeinclude",
            "in",
            "nin",
            "=starts-with",
            "=not-starts-with",
            "=ends-with",
            "!ends-with",
            "after",
            "before"
          ],
          data_types: [
            "NUMBER",
            "STRING",
            "DATE",
            "TIME",
            "DATE_TIME",
            "TIME_RANGE",
            "YES_OR_NO",
            "CURRENCY",
            "LIST",
            "WEEKDAYS",
            "BOOLEAN",
            "created_at",
            "updated_at",
            "created_by",
            "updated_by"
          ]
        },
        {
          value_type: "GLOBAL_VARIABLE",
          operators: [
            "=",
            "<=",
            ">=",
            "<",
            ">",
            "=case-insensitive",
            "=text-includes",
            "=case-sensitive",
            "!="
          ],
          data_types: ["NUMBER", "STRING"]
        },
        {
          value_type: "NEW_LIST",
          operators: ["in", "nin"],
          data_types: ["NUMBER"]
        },

        {
          value_type: "ENTITY_DATA_LIST",
          operators: ["in", "nin"],
          data_types: ["OBJECT_ID", "RELATION"]
        },
        {
          value_type: "REAL_TIME_PICKER",
          operators: ["dateinclude"],
          data_types: ["DATE_TIME"]
        },
        {
          value_type: "CURRENT_DATE",
          operators: ["=", "<=", "<", ">", ">="],
          data_types: ["DATE_TIME", "DATE"]
        }
      ],
      globalVariablesList: [],
      globalVariablesListForInputType: {},
      filters: [],
      entityDataInfo: [],
      loginUserFields: [
        {
          value: "email",
          label: "Email"
        },
        {
          value: "first_name",
          label: "First Name"
        },
        {
          value: "last_name",
          label: "Last Name"
        }
      ],
      groupByFieldEntityValues: [],
      pieChartFieldGroupByOptions: [],
      getTemplateFields: [],
      contentFields: [
        "HEADING",
        "PARAGRAPH",
        "HTML_CONTENT",
        "SINGLE_LINE_CONTENT",
        "VIDEO",
        "ACTION_BUTTON",
        "HORIZONTAL_LINE",
        "DIVISION"
      ],
      allEntitiesFilters: [],
      display_views: [
        {
          label: "Table",
          key: "TABLE"
        },
        {
          label: "Cards",
          key: "CARDS"
        },
        {
          label: "Content",
          key: "HTML_CONTENT"
        },
        {
          label: "Board",
          key: "BOARD"
        },
        {
          label: "Charts",
          key: "CHARTS"
        }
      ],
      filteredList: []
    };
  },
  props: [
    "currentFormbuilder",
    "formbuilderFiltersData",
    "allFormbuilderFields"
  ],
  async mounted() {
    await Promise.all([
      this.fetchGlobalVariables(),
      this.fetchEntityFieldValues()
    ]);
  },
  methods: {
    async fetchEntityFieldValues() {
      let defaultValueSet = false;
      if (
        this.formbuilderFiltersData.filters &&
        this.formbuilderFiltersData.filters.length
      ) {
        this.formbuilderFiltersData.filters.forEach(element => {
          if (
            element &&
            (element.data_type == "RELATION" ||
              element.data_type == "OBJECT_ID") &&
            element.relation_entity_id
          ) {
            this.fetchEntityPrimaryFieldsData(element.relation_entity_id);
          }
          if (!defaultValueSet && element.relational_filter_query) {
            this.default_relational_filter_query =
              element.relational_filter_query;
            defaultValueSet = true;
          }
        });
      }
    },
    deleteFilter() {
      this.$emit("delete", {});
    },
    addFilter() {
      this.formbuilderFiltersData.filters.push({
        ...this.newFilterData,
        ...{
          query_type: this.query_type,
          relational_filter_query: this.default_relational_filter_query
        }
      });
      let scrollbarEl = this.$refs.filterScroll.wrap;
      scrollbarEl.scrollTop = scrollbarEl.scrollHeight + 10;
    },
    getCurrentFieldOperators(filter) {
      if (
        filter.field == "status" ||
        filter.data_type == "STATUS" ||
        filter.field == "isCreatedFromFormbuilder"
      ) {
        return [
          {
            title: "In",
            value: "in"
          },
          {
            title: "Not In",
            value: "nin"
          }
        ];
      }
      if (
        filter.data_type == "created_at" ||
        filter.data_type == "updated_at"
      ) {
        return [
          {
            title: "Greater Than (>)",
            value: ">"
          },
          {
            title: "Less Than (<)",
            value: "<"
          },
          {
            title: "Last Month",
            value: "lastmonth"
          },
          {
            title: "This Month",
            value: "thismonth"
          }
        ];
      }
      if (
        filter.data_type == "created_by" ||
        filter.data_type == "updated_by"
      ) {
        return [
          {
            title: "In",
            value: "in"
          },
          {
            title: "Not In",
            value: "nin"
          }
        ];
      }
      if (filter.field) {
        if (filter.data_type) {
          return this.operatorsMap[filter.data_type];
        } else {
          let field = this.allFormbuilderFields.find(
            field => field.key == filter.field
          );
          if (
            field &&
            field.data_type &&
            field.result_type &&
            field.result_type.length
          ) {
            return this.operatorsMap[field.result_type];
          } else if (field && field.data_type) {
            return this.operatorsMap[field.data_type];
          } else {
            return [];
          }
        }
      } else {
        return [];
      }
    },
    async checkCurrentFieldData(fieldKey, filter) {
      if (fieldKey == "status") {
        filter.data_type = "STATUS";
        filter.data_source = "VALUE";
        return;
      }
      if (fieldKey == "isCreatedFromFormbuilder") {
        filter.data_type = "isCreatedFromFormbuilder";
        filter.data_source = "VALUE";
        return;
      }
      if (fieldKey == "created_at") {
        filter.data_type = "DATE";
        filter.input_type = "created_at";

        return;
      }
      if (fieldKey == "updated_at") {
        filter.data_type = "DATE";
        filter.input_type = "updated_at";

        return;
      }
      if (fieldKey == "created_by") {
        filter.data_type = "created_by";
        filter.input_type = "created_by";

        return;
      }
      if (fieldKey == "updated_by") {
        filter.data_type = "updated_by";
        filter.input_type = "updated_by";

        return;
      }
      let field = this.allFormbuilderFields.find(
        field => field.key == fieldKey
      );
      if (field.inputType == "DATE_TIME" || field.inputType == "DATE") {
        filter.is_date_time = true;
      } else {
        filter.is_date_time = false;
      }
      if (field?.data_table_key) {
        filter.data_table_key = field.data_table_key;
      }
      if (field.input_type == "WEEKDAYS") {
        filter.allow_multiple = field.allow_multiple;
      } else if (field.input_type == "FORMULA") {
        filter.data_type = field.result_type;
        filter.is_formula = true;
      } else if (
        field.input_type == "ENTITY_VARIABLE" &&
        field.inputType == "FORMULA"
      ) {
        filter.data_type = this.getTypeFromInputType(field.result_type);
        field.data_type = this.getTypeFromInputType(field.result_type);
      } else {
        filter.data_type = this.getTypeFromInputType(field.inputType);
        field.data_type = this.getTypeFromInputType(field.inputType);
      }
      if (field.input_type == "RELATION" || field.data_type == "OBJECT_ID") {
        filter.relation_entity_id = field.entity_id;
      }
      this.$set(filter, "data_source", "");
      if (field.inputType == "SELECT" || field.inputType == "MULTI_SELECT") {
        this.$set(filter, "value", []);
      } else {
        this.$set(filter, "value", "");
      }
      filter.input_type = field.input_type;
      await this.getEntilistAsOptions(filter);
    },
    async getEntilistAsOptions(filter) {
      let fieldKey = filter.field;
      let field = this.allFormbuilderFields.find(
        field => field.key == fieldKey
      );
      if (field.input_type == "ENTITY" || field.input_type == "RELATION") {
        await this.fetchEntityPrimaryFieldsData(field.entity_id);
      }
    },
    async fetchEntityPrimaryFieldsData(entity_id) {
      try {
        let params = {
          entity_id: entity_id,
          template_fields_data: [],
          filters: []
        };
        let response = await postAPICall(
          "POST",
          "/entities-data/entity/data",
          params
        );
        if (response.data) {
          this.entityDataInfo.push({
            entity: response.selectedEntity,
            data: response.data,
            entity_id: entity_id
          });
        }
      } catch (err) {
        console.log("Error while fetchEntityPrimaryFieldsData", err);
      }
    },
    getLabel(item, i) {
      let selectedEntity = (this.entityDataInfo || []).find(
        e => e.entity_id == item.entity_id
      );
      if (selectedEntity && selectedEntity.entity) {
        selectedEntity = selectedEntity.entity;
      }
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          name =
            item.entityData &&
            primaryField.template_id &&
            item.entityData[primaryField.template_id]
              ? item.entityData[primaryField.template_id][key + "/name"]
                ? item.entityData[primaryField.template_id][key + "/name"]
                : item.entityData[primaryField.template_id][key]
                ? item.entityData[primaryField.template_id][key]
                : ""
              : "";
          if (!name) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          }
        }
      }
      return name;
    },
    getTypeFromInputType(inputType) {
      const obj = {
        SINGLE_LINE_TEXT: "STRING",
        MULTI_LINE_TEXT: "STRING",
        DATE: "DATE",
        DATE_TIME: "DATE",
        TIME: "TIME",
        NUMBER: "NUMBER",
        FILE: "FILE",
        SELECT: "ARRAY",
        YES_OR_NO: "YES_OR_NO",
        MULTI_SELECT: "ARRAY",
        CHECKBOX_GROUP: "ARRAY",
        RADIO_BUTTON_GROUP: "ARRAY",
        CHECKBOX: "BOOLEAN",
        RADIO: "STRING",
        HEADING: "STRING",
        SIGNATURE: "FILE",
        GLOBAL_VARIABLE: "OBJECT_ID",
        TEMPLATE_FIELD: "OBJECT_ID",
        ENTITY: "OBJECT_ID",
        IMAGE: "STRING",
        PASSWORD: "STRING",
        MASKED: "STRING",
        EMAIL: "EMAIL",
        PHONE: "PHONE",
        FAX: "NUMBER",
        ZIP_CODE: "ZIP_CODE",
        CONCATENATE: "CONCATENATE",
        CURRENCY: "NUMBER",
        PHONE_COUNTRY_CODE: "PHONE",
        QUESTION: "STRING",
        DATE_TIME_RANGE: "DATE_TIME",
        DATE_RANGE: "DATE_RANGE",
        WEEKDAYS: "WEEKDAYS",
        TIME_RANGE: "TIME_RANGE",
        RELATION: "RELATION",
        LIST: "ARRAY",
        Hyper_link: "STRING",
        STAR_RATING: "ARRAY",
        PAY_BUTTON: "PAYMENT",
        AGGREGATE_FUNCTION: "NUMBER",
        AUTO_INCREMENT_NUMBER: "STRING"
      };
      return obj[inputType];
    },
    allowedDataSources(filter) {
      let operatorValues = this.operatorValueTypeMap.filter(valueType => {
        return (
          valueType.operators.indexOf(filter.operator) > -1 &&
          valueType.data_types.indexOf(filter.data_type) > -1
        );
      });
      return operatorValues.map(e => e.value_type);
    },
    async checkSourceDataChange(source, filter) {
      if (
        filter.data_type == "created_by" ||
        filter.data_type == "updated_by"
      ) {
        return;
      }
      if (source == "GLOBAL_VARIABLE") {
        // await this.getGlobalVariables(source, filter);
        // we have to fetch global variables
      }
    },
    removeFilterFromList(index) {
      const filteredList = this.formbuilderFiltersData.filters;
      const filterToRemove = filteredList[index];
      if (filterToRemove) {
        let originalIndex;
        originalIndex = this.formbuilderFiltersData.filters.findIndex(
          filter => filter === filterToRemove
        );
        if (originalIndex > -1) {
          this.formbuilderFiltersData.filters.splice(originalIndex, 1);
        }
      }
    },
    checkTemplateCondition(filter) {
      if (
        filter.data_type != "created_by" &&
        filter.data_type != "updated_by" &&
        filter.operator != "filter"
      ) {
        return true;
      }
    },
    checkIsSelect(filter) {
      let field = this.allFormbuilderFields.find(
        field => field.key == filter.field
      );
      if (
        field &&
        (field.inputType == "SELECT" ||
          field.inputType == "MULTI_SELECT" ||
          field.inputType == "CHECKBOX_GROUP" ||
          field.input_type == "RADIO_BUTTON_GROUP" ||
          field.inputType == "LIST" ||
          field.input_type == "WEEKDAYS" ||
          field.inputType == "STAR_RATING" ||
          field.input_type == "PAY_BUTTON")
      ) {
        return true;
      }
      return false;
    },
    getSelfTemplateFieldsByType(filter) {
      let field = this.allFormbuilderFields.find(
        field => field.key == filter.field
      );
      if (
        (field && field.inputType === "NUMBER") ||
        field.inputType === "CURRENCY" ||
        field.inputType === "AGGREGATE_FUNCTION" ||
        (field.inputType === "FORMULA" && field.result_type === "NUMBER")
      ) {
        return (this.allFormbuilderFields || []).filter(
          e =>
            (e.data_type === "NUMBER" ||
              (e.input_type === "FORMULA" && e.result_type !== "DATE") ||
              e.input_type === "AGGREGATE_FUNCTION" ||
              (e.input_type === "ENTITY_VARIABLE" &&
                e.inputType === "NUMBER")) &&
            e.key !== field.key
        );
      }
      if (
        (field && field.inputType === "DATE") ||
        field.inputType === "FORMULA"
      ) {
        return (this.allFormbuilderFields || []).filter(
          e =>
            (e.data_type === "DATE" ||
              (e.input_type === "FORMULA" && e.result_type === "DATE")) &&
            e.key !== field.key
        );
      } else if (field && field.input_type) {
        return (this.allFormbuilderFields || []).filter(
          e =>
            e?.key &&
            ((field.inputType != "ENTITY" && e.inputType == field.inputType) ||
              (field.inputType == "ENTITY" &&
                field.entity_id == e.entity_id &&
                e.inputType == "ENTITY") ||
              (["MULTI_SELECT", "SELECT"].indexOf(field.inputType) != -1 &&
                ["MULTI_SELECT", "SELECT"].indexOf(e.inputType) != -1) ||
              (["TIME", "TIME_RANGE"].indexOf(field.inputType) != -1 &&
                ["TIME", "TIME_RANGE"].indexOf(e.inputType) != -1)) &&
            e.key != field.key
          // e.relationship_key != this.field.key
        );
      }

      return [];
    },
    checkCurrentFieldValue(field, filter) {
      filter.value = field;
    },
    parseOptionsAsNumbers(data, filter) {
      if (filter.data_source == "NEW_LIST" && filter.data_type == "NUMBER") {
        filter.value = filter.value.map(e => +e);
      }
    },
    async fetchGlobalVariables() {
      try {
        const query = { get_all: true };

        await this.$store.dispatch(
          "globalVariables/fetchGlobalVariables",
          query
        );
        if (this.getAllGlobalVariables.data) {
          this.globalVariablesList = [...this.getAllGlobalVariables.data];
        }
      } catch (err) {
        console.log(err);
      }
    },
    addGlobalVaraibleData(value, filter) {
      let selectedGlobalVariable = this.selectedGlobalVariablesList(
        filter
      ).find(e => e._id == value);
      if (selectedGlobalVariable?.value) {
        filter.value = selectedGlobalVariable.value;
      }
    },
    selectedGlobalVariablesList(filter) {
      let field = this.allFormbuilderFields.find(e => e.key == filter.field);
      return this.globalVariablesList.filter(
        x => x && field && x.input_type == field.input_type
      );
    },
    fieldType(filter, type) {
      if (filter.field) {
        let field = this.allFormbuilderFields.find(
          field => field.key == filter.field
        );
        return field?.input_type == type;
      }
      return [];
    },
    getCurrentFieldOptions(filter) {
      if (filter.selectField === "created_at") {
        filter.data_type = "DATE";
        filter.input_type = "created_at";
        return;
      }
      if (filter.selectField === "updated_at") {
        filter.data_type = "DATE";
        filter.input_type = "updated_at";
        return;
      }
      if (filter.selectField === "created_by") {
        filter.data_type = "created_by";
        filter.input_type = "created_by";
        return;
      }
      if (filter.selectField === "updated_by") {
        filter.data_type = "updated_by";
        filter.input_type = "updated_by";
        return;
      }
      if (filter.field) {
        let field = this.allFormbuilderFields.find(
          field => field.key == filter.field
        );
        if (field?.input_type == "LIST" && field?.list_data) {
          return field.list_data;
        }
        if (field && field.input_type == "PAY_BUTTON" && field.pay_status) {
          return field.pay_status;
        } else if (field?.input_type == "WEEKDAYS") {
          let weekdays_names = this.weekdays_options.map(
            weekday => weekday.name
          );
          return weekdays_names;
        } else if (
          field?.input_type == "STAR_RATING" ||
          (field?.input_type == "ENTITY_VARIABLE" &&
            field?.inputType == "STAR_RATING")
        ) {
          let starRating_values = this.starRating_options.map(
            starRating => starRating.value
          );
          return starRating_values;
        } else {
          return field?.options || [];
        }
      } else {
        return [];
      }
    }
  }
};
</script>

<style lang="scss">
</style>
