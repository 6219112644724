<template>
  <section>
    <el-row>
      <i class="el-icon-s-tools" @click="openSettings"></i>
    </el-row>
    <div class="carousel-container">
      <!-- Render only the first slide -->
      <div
        class="carousel-slide single-slide"
        v-if="data.carousel_cards && data.carousel_cards.length"
        :style="{ backgroundImage: `url(${data.carousel_cards[0].imageUrl})` }"
      >
        <div class="slide-content">
          <h2>{{ data.carousel_cards[0].title }}</h2>
          <p>{{ data.carousel_cards[0].description }}</p>
          <a :href="data.carousel_cards[0].link" target="_blank">
            <button class="action-btn">
              {{ data.carousel_cards[0].buttonName }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["data"],
  mounted() {},
  methods: {
    openSettings() {
      this.$emit("settings");
    },
  },
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
}

.carousel-slide.single-slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.carousel-slide.single-slide:hover {
  transform: scale(1.05);
}

.slide-content {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 10px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
}

.action-btn {
  background-color: var(--lighter-primary-color);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.action-btn:hover {
  background-color: var(--darker-primary-color);
}
</style>
