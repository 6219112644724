<template>
  <div v-loading.fullscreen.lock="componentLoading">
    <el-tooltip
      :content="'Add New ' + relationshipDetails.relationship_title"
      v-if="!autoPopup"
    >
      <el-button
        class="right-block float-right add-buttons mt-0 mb-1"
        style="padding: 6px 10px !important"
        type="primary"
        @click="showAssignEntities"
        :disabled="
          relationshipDetails.allowOne && existedData.length ? true : false
        "
      >
        <i class="el-icon-circle-plus-outline pr-1"></i>
        {{ relationshipDetails.addNewLabel ? relationshipDetails.addNewLabel : 'Add New' }}
      </el-button>
    </el-tooltip>

    <dialog-component
      :title="'Add New  ' + relationshipDetails.relationship_title + `'s Data`"
      :visible="addEntitiesDialogVisible"
      @before-close="addNewClosed"
      :fullscreen="true"
      :width="getIsMobile ? '100%' : '100%'"
      class="el-dialog el-dialog-fullscreen"
      :containerWidth="'100%'"
      :containerHeight="'100%'"
    >
      <!-- <div class="sidemenu" v-loading="loading" v-if="getChildEntityDataById"> -->
        <!-- <p>New {{ getChildEntityDataById.name }} - Primary Data</p>
        <hr />

        <el-form
          ref="form"
          :model="form"
          v-if="getChildEntityDataById && getChildEntityDataById.primaryFields"
        >
          <PrimaryDataForm
            :fieldsListData="childPrimaryFields"
            :form="form"
            :relationshipDetails="relationshipDetails"
            :childRelationshipsData="childRelationshipsData"
            :parentyEntityId="getChildEntityDataById._id"
          >
          </PrimaryDataForm>
        </el-form> -->
        <template v-if="getChildEntityDataById && getChildEntityDataById.templates">
          <fields-preview-template
            :templateData="getChildEntityDataById.templates[0].templateInfo"
            :has-next=false
            :workflow-step-id="getChildEntityDataById.templates[0]._id"
            :entityDataId="null"
            :template-data-id="getChildEntityDataById.templates[0].templateDataId"
            :currentStep="getChildEntityDataById.templates[0]"
            :is-execute="true"
            :layout="layout"
            :currentEntity="getChildEntityDataById"
            :isEdit="true"
            :entityData="null"
            :currentMenu="null"
            :entityDataExists="false"
            :fromAddWidget="true"
            :type="'s7aLF3NnAQg='"
            @saveAndAssignRelation="saveAndAssignRelation"
          >
        </fields-preview-template>
        </template>
        <!-- 
          
        :templateData="getChildEntityDataById?.templates[0]?.templateInfo"
        :has-next=false
        :workflow-step-id="getChildEntityDataById?.templates[0]._id"
        :entityDataId="newEntityDataId"
        :template-data-id="getChildEntityDataById?.templates[0].templateDataId"
        :currentStep="getChildEntityDataById.templates[0]"
        :is-execute=true
        :layout="layout"
        :currentEntity="getChildEntityDataById"
        :isEdit="true"
        :entityData="null"
        :currentMenu="null"
        :entityDataExists="false" -->
      <!-- </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addEntitiesDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addNewRelationship"
          >{{ relationshipDetails.addDataLabel ? relationshipDetails.addDataLabel : 'Add Data'}}</el-button
        >
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";

export default {
  props: ["relationshipDetails", "existedData", "autoPopup","totalRecords","currentEntityData"],
  mixins: [entityRelationshipMixin],
  data() {
    return {
      addEntitiesDialogVisible: false,
      childEntitiesData: [],
      selectedChildEntitiesData: [],
      childPrimaryFields: [],
      loading: false,
      entityId: "",
      form: {},
      relationshipsData: [],
      childRelationshipsData: [],
      standardTemplate: null,
      createdEntityDataId : null,
      layout:'STANDARD',
      componentLoading : false
    };
  },
  components: {
    //PrimaryDataForm: () => import("../entity/PrimaryDataCollectionForm"),
    FieldsPreviewTemplate: () =>
      import("../templates/templateFieldsPreview.vue"),
  },
  mounted() {
    if (this.autoPopup) {
      this.showAssignEntities();
    }
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getChildEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getCompanyEntityDetails",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationshipDataAssignStatus",
      "getEntityRelationships",
    ]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getTemplatesData",
      "getTemplatesPrimaryData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityCreateError",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),

    getAllSteps() {
      if (
        this.getChildEntityDataById &&
        this.getChildEntityDataById.templates &&
        this.getChildEntityDataById.templates.length
      ) {
        return this.getChildEntityDataById.templates;
      }

      return [];
    },
  },
  methods: {
    async saveAndAssignRelation(entityDataId){
      if(this.relationshipDetails.representation == 'FIELD'){
        this.$emit("entityDataAssigned")
      }
      else{
        this.selectedChildEntitiesData = [entityDataId]
        await this.assignEntitiesData()
      }
      //this.$emit("entityDataAssigned")
    },
    async showAssignEntities() {
      this.componentLoading = true
      if (
        !this.relationshipDetails.parent_entity_data_id ||
        !this.relationshipDetails.parent_entity_data_id.length
      ) {
        let msg = this.relationshipDetails?.parent_relation_title ? 'Please add the primary details of ' + this.relationshipDetails.parent_relation_title : 'Please add the primary details of current entity';
        this.$message({
          message: msg,
          type: "warning",
        });
        this.componentLoading = false;
        return;
      }
      if(this.relationshipDetails && this.relationshipDetails.apply_limit && this.relationshipDetails.limit_type){
        if(this.relationshipDetails.limit_type == 'value'){
          if(this.totalRecords >= this.relationshipDetails.limit_value){
            this.$notify.error({
              message : this.relationshipDetails.warning_message ? this.relationshipDetails.warning_message : "You have reached the limit",
              title : 'Error'
            })
            this.componentLoading = false
            return
          }
        }
        else if(this.relationshipDetails.limit_type == 'field'){
          let limitRelatedTemplateData = this.currentEntityData?.templates_data.find(dt => dt.template_id.toString() == this.relationshipDetails.limit_field.split('#')[0].toString())
          if(limitRelatedTemplateData !== undefined){
            let value = limitRelatedTemplateData?.template_data_id?.template_data[this.relationshipDetails.limit_field.split('#')[1]]
            if(value && this.totalRecords >= value ){
              this.$notify.error({
              message : this.relationshipDetails.warning_message ? this.relationshipDetails.warning_message : "You have reached the limit",
              title : 'Error'
            })
            this.componentLoading = false
            return
            }
          }
          
        }
      }
      await this.fetchChildEntityData();
      this.componentLoading = false
      this.addEntitiesDialogVisible = true;
      this.form = {};
    },

    getTotalCount() {
      return this.getChildEntityDataById.primaryFields.filter((e) => e.key)
        .length;
    },

    addNewClosed() {
      this.addEntitiesDialogVisible = false
      this.$emit("entityDataClosed");
    },

    getRequired() {
      return this.getChildEntityDataById.primaryFields.filter(
        (e) => this.form[e.key]
      ).length;
    },
    getIndividualEntityPrimaryFields() {
      let result = [];
      this.getChildEntityDataById.templates.forEach((e) => {
        if (
          e.templateInfo &&
          e.templateInfo.slug == "contactdetails" &&
          e.templateInfo.type == "STANDARD"
        ) {
          this.standardTemplate = e.template_id;
          result = e.templateInfo.sections[0].fields.filter(
            (el) =>
              el.key == "email" ||
              el.key == "last_name" ||
              el.key == "first_name"
          );
        }
      });
      return result;
    },
    async fetchChildEntityData() {
      this.loading = true;
      this.entityId = this.relationshipDetails.relationship_id;

      await this.$store.dispatch(
        "entities/fetchChildEntityById",
        this.entityId
      );
      this.$store.commit(
        "templatesData/setTemplatesPrimaryData",
        {},
        {
          root: true,
        }
      );

      if (this.getAllSteps && this.getAllSteps.length) {
        if (this.getChildEntityDataById) {
          if (
            this.getChildEntityDataById &&
            this.getChildEntityDataById.templates &&
            this.getChildEntityDataById.templates.length
          ) {
            if (
              this.getChildEntityDataById.primaryFields &&
              this.getChildEntityDataById.primaryFields.length
            ) {
              if (
                this.getChildEntityDataById.primaryFields &&
                this.relationshipDetails.representation == "TAB"
              ) {
                if (this.getChildEntityDataById?.entity_type == "INDIVIDUAL") {
                  this.childPrimaryFields =
                    this.getIndividualEntityPrimaryFields();
                } else {
                  this.childPrimaryFields =
                    this.getChildEntityDataById.primaryFields;
                }
              } else {
                if (this.getChildEntityDataById?.entity_type == "INDIVIDUAL") {
                  this.childPrimaryFields =
                    this.getIndividualEntityPrimaryFields();
                } else {
                  this.childPrimaryFields =
                    this.getChildEntityDataById.primaryFields;
                }

                let templatesFields = this.getChildEntityDataById.templates
                  .map((e) => {
                    return e.templateInfo.sections[0].fields.map((f) => {
                      f.template_id = e.templateInfo._id;
                      return f;
                    });
                  })
                  .reduce(function (a, b) {
                    return a.concat(b);
                  }, []);

                templatesFields = templatesFields.filter((e) => {
                  if (
                    e.input_type == "ENTITY" &&
                    (e.entity_id ||
                      (e.filters &&
                        e.filters.find(
                          (filter) => filter.field == "self#related_to"
                        )))
                  ) {
                    e.not_primary_field = true;
                    return true;
                  } else {
                    return false;
                  }
                });

                let currentRealtedFieldIndex = templatesFields.findIndex(
                  (e) => {
                    return (
                      e._id == this.relationshipDetails.field_id &&
                      this.relationshipDetails.representation == "FIELD" &&
                      e.entity_id == this.relationshipDetails.child_entity_id
                    );
                  }
                );

                if (currentRealtedFieldIndex > -1) {
                  templatesFields[
                    currentRealtedFieldIndex
                  ].default_entity_data_ids = [
                    this.relationshipDetails.parent_entity_data_id,
                  ];

                  let entityId =
                    templatesFields[currentRealtedFieldIndex].entity_id;

                  templatesFields.forEach((field, index) => {
                    if (
                      field.input_type == "ENTITY" &&
                      index != currentRealtedFieldIndex
                    ) {
                      let relatedFilter = (
                        templatesFields[currentRealtedFieldIndex].filters || []
                      ).find((f) => f.field == "self#related_to");

                      if (relatedFilter) {
                        field.dependency_related_entity_id = entityId;
                        field.dependency_related_entity_data_id =
                          this.relationshipDetails.parent_entity_data_id;

                        templatesFields[
                          currentRealtedFieldIndex
                        ].parent_entity_related =
                          relatedFilter.relation_entity_id;
                      }
                    }
                  });
                }
                let childPrimaryFieldsMap = {};

                this.childPrimaryFields.forEach((e) => {
                  childPrimaryFieldsMap[e.key] = e;
                });

                templatesFields = templatesFields.filter((e) => {
                  let findIndex = this.childPrimaryFields.findIndex(
                    (x) => x.key == e.key
                  );

                  if (findIndex > -1) {
                    this.childPrimaryFields[findIndex] = e;
                    return false;
                  }
                  return true;
                });

                this.childPrimaryFields = [
                  ...this.childPrimaryFields,
                  ...templatesFields,
                ];
              }

              this.addPrimaryDataDialog = true;

              await this.fetchChildEntityRelationships();

              this.errorDialog = {
                status: false,
                message: ``,
              };

              this.loading = false;
            } else {
              this.errorDialog = {
                status: true,
                message: `${this.getChildEntityDataById.name} entity does not have primary fields.`,
              };
            }
          } else {
            this.errorDialog = {
              status: true,
              message: `${this.getChildEntityDataById.name} entity does not have templates.`,
            };
          }
        }
      } else {
        this.errorDialog = {
          status: true,
          message: `${this.getChildEntityDataById.name} entity does not have templates.`,
        };
      }

      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },

    async fetchChildEntityRelationships() {
      this.loading = true;
      this.loadingText = "Fetching Entity relationships..";
      await this.$store.dispatch(
        "entityRelationships/fetchEntityRelationships",
        { entity_id: this.entityId }
      );

      this.childRelationshipsData = [...(this.getEntityRelationships || [])];
      // we have to add type owner of relationship
      this.childRelationshipsData = this.childRelationshipsData.map((e) => {
        if (e.parent_entity_id == this.entityId) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });
      this.loading = false;
    },

    async addNewRelationship() {
      try {
        // if (this.getChildEntityDataById?.entity_type == "INDIVIDUAL") {
        //   await this.addIndividualEntityData();
        // } else {
        //   await this.addPrimaryDataToEntity();
        // }
        this.$bus.$emit('saveTemplateFromRelationship',this.getChildEntityDataById._id)
      } catch (err) {
        console.log("addnewRelationship",err);
      }
    },
    validateIndividualData() {
      if (
        this.form &&
        this.form.first_name &&
        this.form.last_name &&
        this.form.email
      ) {
        let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let firstNameRe =
          /[a-zA-Z]{1,}/ || /(?:[A-Za-z].*?\d|\d.*?[A-Za-z]){1,}/;
        if (!re.test(this.form.email)) {
          return {
            success: false,
            message: "Invalid mail address",
          };
        } else if (!firstNameRe.test(this.form.first_name)) {
          return {
            success: false,
            message: "Invalid first name address",
          };
        }
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: "Please fill all fields",
        };
      }
    },
    async addIndividualEntityData() {
      let checkingData = this.validateIndividualData();
      if (checkingData.success) {
        this.loading = true;
        this.form["name"] = this.form.first_name + " " + this.form.last_name;
        let params = {
          entity_details_id: null,
          template_id: this.standardTemplate,
          template_data: this.form,
          entity_id: this.getChildEntityDataById._id,
          template_completion_status: true,
          entity_data_id: null,
        };
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          params
        );
        if (this.getEntityDataCreateByTemplateStatus && this.getNewEntityData) {
          this.selectedChildEntitiesData = [this.getNewEntityData._id];
          await this.updateEntitiesData();
        } else {
          this.loading = false;
          if (
            this.getNewEntityCreateError &&
            this.getNewEntityCreateError.message
          ) {
            this.$notify.error({
              title: "Error",
              message: this.getNewEntityCreateError.message,
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving data",
            });
          }
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: checkingData.message,
        });
      }
    },
    async addPrimaryDataToEntity() {
      try {
        this.loading = true;
        if (this.form && this.getRequired() === this.getTotalCount()) {
          //  if(this.getEntityDataById.primaryFields === 'PHONE_COUNTRY_CODE'){
          //    this.$set(this.form,'countryCallingCode',data.countryCallingCode);
          //  }

          this.childPrimaryFields.forEach((e) => {
            if (
              e.input_type == "ENTITY" &&
              e.entity_id &&
              this.form[e.key] &&
              this.form[e.key + "/name"]
            ) {
              e["entity_data_key"] = e.key + "/name";
            }
          });
          let data = {
            primaryFields: this.childPrimaryFields,
            formData: this.form,
            templates: this.getChildEntityDataById.templates,
            entity_id: this.entityId,
          };
          await this.$store.dispatch(
            "templatesData/createEntityPrimaryData",
            data
          );

          if (this.getPrimaryEntityDataStatus) {
            if (this.getNewEntityData) {
              this.selectedChildEntitiesData = [this.getNewEntityData._id];
              await this.updateEntitiesData();
            }
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving data",
            });
          }
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }

        setTimeout(() => {
          this.loading = false;
        }, 1500);
      } catch (err) {
        console.log("addPrimaryDataToEntity",err);
      }
    },

    async updateEntitiesData() {
      try {
        this.loading = true;
        this.loadingText = "Adding Entity details";

        // ** mixin method ** //
        let toAssignRelationshipsData = this.prepareRelationshipData(
          this.childPrimaryFields,
          this.childRelationshipsData,
          this.selectedChildEntitiesData[0]
        );

        if (toAssignRelationshipsData.length) {
          await this.$store.dispatch(
            "entityRelationships/assignFieldsEntityRelationshipData",
            toAssignRelationshipsData
          );
          this.loading = false;

          this.$emit("entityDataAssigned");
          this.addEntitiesDialogVisible = false;
        } else {
          await this.assignEntitiesData();
        }
      } catch (err) {
        console.log("updateEntitiesData",err);
      }
    },

    async assignEntitiesData() {
      this.loading = true;
      try {
        let data;
        if (this.relationshipDetails.owner_type == "PARENT") {
          data = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: this.selectedChildEntitiesData,
          };
        } else {
          if (this.relationshipDetails.parent_relation_type == "MANY") {
            data = {
              parent_entity_id: this.relationshipDetails.child_entity_id,
              child_entity_id: this.relationshipDetails.parent_entity_id,
              parent_entity_data_id:
                this.relationshipDetails.parent_entity_data_id,
              child_entity_data_ids: this.selectedChildEntitiesData,
            };
          } else {
            data = {
              parent_entity_id: this.relationshipDetails.parent_entity_id,
              child_entity_id: this.relationshipDetails.child_entity_id,
              parent_entity_data_id: this.selectedChildEntitiesData[0],
              child_entity_data_ids: [
                this.relationshipDetails.parent_entity_data_id,
              ],
            };
          }
        }

        await this.$store.dispatch(
          "entityRelationships/assignEntityRelationshipData",
          data
        );

        if (this.getEntityRelationshipDataAssignStatus) {
          this.$message.success(
            `${this.relationshipDetails.relationship_title} data added successfully`
          );

          this.$emit("entityDataAssigned");
          this.addEntitiesDialogVisible = false;
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
  },
  watch: {
    existedData(newValue) {
      // ...
      console.log(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-buttons {
  border: 1.5px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 32px;
  color: var(--primary-contrast-color);
  &:hover {
    background-color: var(--primary-color);
    color: #ffffff;
    border-color: var(--primary-color);
  }
}
.sidemenu {
  height: 90vh;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.relation-add{
  .el-dialog{
    height:100vh !important;
    width: 83vw;
    height: 100%;
    position: fixed;
    top: 0;
  }
  .el-dialog__body {
    max-height: 70vh !important;
    padding: 1em 1.5em !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    .sidemenu{
      max-height: 74vh !important;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
        width: initial;
        height: initial;
    }

    &::-webkit-scrollbar-thumb {
        width: initial;
        height: initial;
        border-radius: initial;
        background-clip: initial;
        border: initial;
    }

    /* Add the specific styles for the webkit scrollbar in el-dialog__body */
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar-thumb {
        width: 0px;
        height: 0px;
        border-radius: 10px;
        background-clip: padding-box;
        border: 10px solid rgba(0, 0, 0, 0.28);
    }
}
}

</style>

<style scoped>
.el-button {
    padding: 6px 20px;
    font-size: 14px;
    border-radius: 4px;
}
</style>

