<template>
  <div
    v-loading="laoading"
    element-loading-spinner="el-icon-loading"
    :element-loading-text="loadingText"
  >
    <el-row :gutter="20">
      <el-col v-for="(field, index) in fields" :key="index" :span="7">
        <el-form :model="row">
          <el-form-item
            :label="field.label"
            :prop="field.key"
            :rules="[
              {
                required: field.isRequired,
                message: `${field.label} is required`,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-if="field.type == 'text'"
              v-model="row[field.key]"
              :disabled="field.isDisabled"
              :placeholder="'Enter ' + field.label"
            />
            <el-select
              v-else-if="field.type == 'select'"
              v-model="row[field.key]"
              :placeholder="'Select ' + field.label"
            >
              <el-option
                v-for="(item, index) in field.values"
                :key="index"
                :label="item.name"
                :value="item.id"
            /></el-select>
            <el-date-picker
              v-else-if="field.type == 'dateTime'"
              v-model="row[field.key]"
              type="datetime"
              placeholder="Select date and time"
              :disabled="field.isDisabled"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { postAPICall } from "@/helpers/httpHelper";
export default {
  name: "editRecord",
  props: {
    fields: {
      type: Array,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    componentData: {
      type: Object,
      required: true,
    },
    integrationDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { laoading: false, loadingText: "" };
  },
  mounted() {
    this.apiData();
  },
  methods: {
    async apiData() {
      try {
        await Promise.all(
          this.fields.map(async (field) => {
            if (field.isApiData) {
              let payload = {
                appName: this.integrationDetails.matchedApplication.key,
                connectionId:
                  this.integrationDetails.matchedApplication.integration
                    .connection_id,
                field: field.requiredAsset,
                parameters: this.componentData.dependencies,
              };
              this.laoading = true;
              this.loadingText = "fetching..";
              let response = await postAPICall(
                "POST",
                "integrations/service/get-user-data",
                payload
              );
              this.laoading = false;
              if (field.type == "select") {
                field["values"] = response.data;
              }
            }
          })
        );
        return this.fields;
      } catch (err) {
        return err;
      }
    },
  },
};
</script>
