<template>
  <section
    class="user-profile-entity-document-templates"
    v-loading.fullscreen.lock="configureLoading"
    :element-loading-text="LoadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-table
      class="templates-table"
      v-if="!getIsMobile && !this.isADocument"
      :total="total"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :pagination-props="paginationProps"
      @query-change="loadData"
      :data="documentTemplatesByTemplateId"
      border
      @sort-change="sortChange"
      v-loading="configureLoading"
      :element-loading-text="LoadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column
        v-if="showIsSingleTable"
        label="Name"
        min-width="250"
        prop="name"
        id="name"
        sortable="custom"
      >
      </el-table-column>     
      <el-table-column
        label="Sent On"
        min-width="120"
        prop="created_at"
        id="created_at"
        sortable="custom"
      >
        <template slot-scope="scope">
          <p v-if="scope.row && scope.row.created_at" style="font-size: 14px">
            {{ scope.row.created_at | globalDateTimeFormat }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Tracking Status"
        prop="document_tracking_status"
        min-width="120"
      >
        <template
          slot-scope="scope"
          v-if="
            scope.row.document_tracking_status &&
            scope.row.document_tracking_status.type
          "
        >
          <p
            slot="reference"
            style="font-family: inter; font-size: 14px"
            class="text-secondary-sent"
          >
            {{
              scope.row.document_tracking_status.description
                | truncate(18, "...")
            }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="document_status"
        label="Document Status"
        id="document_status"
        min-width="120"
        sortable="custom"
      >
        <template
          slot-scope="scope"
          v-if="scope.row && scope.row.document_status"
          style="font-size: 14px"
        >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'COMPLETED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Completed</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'VOIDED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Voided</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'SENT'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
          >
            Sent
          </span>
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'APPROVED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Approved</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'DECLINED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Declined</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'EXPIRED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Expired</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'DRAFT'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Draft</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'ARCHIVED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Archived</span
          >
        </template>
      </el-table-column>

      <el-table-column
        label="Completed At"
        prop="completed_at"
        id="completed_at"
        min-width="120"
        sortable="custom"
      >
        <template slot-scope="scope" v-if="scope.row">
          <p v-if="scope.row && scope.row.completed_at" style="font-size: 14px">
            {{ scope.row.completed_at | globalDateTimeFormat }}
          </p>
          <p v-else style="text-align: center">- -</p>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="150">
        <template slot-scope="scope" v-if="scope.row">
          <ul>
            <div style="display: flex">
              <el-tooltip content="View Document" placement="top-start">
                <li>
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2"
                    @click="onView(scope.row._id)"
                  >
                    <i class="el-icon-view"></i>
                  </el-button>
                </li>
              </el-tooltip>
              <el-tooltip
                content="Resend Document"
                placement="top-start"
                v-if="
                  scope.row &&
                  scope.row.document_status &&
                  (scope.row.document_status == 'SENT' ||
                    scope.row.document_status == 'APPROVER') &&
                  !scope.row.document_filling_status &&
                  checkToOnlyResend(scope.row)
                "
              >
                <li>
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2"
                    @click="resendDocLink(scope.row)"
                  >
                    <i class="el-icon-position"></i>
                  </el-button>
                </li>
              </el-tooltip>
              <el-tooltip
                content="Download Document"
                placement="top-start"
                v-else-if="scope.row.document_status == 'COMPLETED'"
              >
                <li>
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2"
                    @click="onDownload(scope.row)"
                  >
                    <i class="el-icon-download"></i>
                  </el-button>
                </li>
              </el-tooltip>
              <li>
                <!-- *** need to work on it *** -->
                <el-dropdown>
                  <img
                    src="@/assets/img/icons/more-icon.svg"
                    alt="icon"
                    class="el-dropdown-link"
                  />
                  <el-dropdown-menu slot="dropdown">
                    <a
                      @click="alertCorrectedDoc(scope.row)"
                      v-if="
                        (checkPermissionForDocument(
                          scope.row,
                          'correctDocument'
                        ) &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'ARCHIVED' &&
                          scope.row.document_status != 'EXPIRED') ||
                        isfilledOrNot(scope.row)
                      "
                    >
                      <el-dropdown-item
                        ><i class="el-icon-document-checked"></i> Correct the
                        document</el-dropdown-item
                      >
                    </a>
                    <a
                      @click="EditVoidDocument(scope.row._id)"
                      v-if="
                        checkPermissionForDocument(
                          scope.row,
                          'cloneDocument'
                        ) &&
                        (scope.row.document_status == 'VOIDED' ||
                          scope.row.document_status == 'COMPLETED')
                      "
                    >
                      <el-dropdown-item
                        ><i class="el-icon-document-copy"></i>
                        Clone</el-dropdown-item
                      >
                    </a>
                    <a> </a>
                    <a
                      @click="alertvoided(scope.row)"
                      v-if="
                        checkPermissionForDocument(scope.row, 'voidDocument') &&
                        scope.row &&
                        scope.row.document_status &&
                        scope.row.document_status != 'VOIDED' &&
                        scope.row.document_status != 'DECLINED' &&
                        scope.row.document_status != 'COMPLETED' &&
                        scope.row.document_status != 'EXPIRED' &&
                        scope.row.document_status != 'ARCHIVED'
                      "
                    >
                      <el-dropdown-item>
                        <i class="el-icon-remove-outline"></i>Void Document
                      </el-dropdown-item>
                    </a>
                    <a
                      v-if="
                        checkPermissionForDocument(
                          scope.row,
                          'deleteDocument'
                        ) && scope.row.document_status != 'ARCHIVED'
                      "
                      @click="archiveDocument(scope.row)"
                    >
                      <el-dropdown-item
                        ><i class="el-icon-delete"></i>Delete</el-dropdown-item
                      >
                    </a>
                    <a
                      v-if="
                        scope.row &&
                        scope.row.document_status &&
                        scope.row.document_status === 'ARCHIVED'
                      "
                      @click="alertUndoDoc(scope.row)"
                    >
                      <el-dropdown-item>
                        <i class="el-icon-refresh-left"></i>Undo Delete
                      </el-dropdown-item>
                    </a>
                    <a
                      v-if="
                        scope.row &&
                        scope.row.document_status &&
                        scope.row.document_status === 'ARCHIVED'
                      "
                      @click="permanentDocument(scope.row)"
                    >
                      <el-dropdown-item>
                        <i class="el-icon-delete-solid"></i>Permanent Delete
                      </el-dropdown-item>
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- **** need to work on it *** -->
              </li>
            </div>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      class="templates-table-is-mobile"
      v-if="getIsMobile && !this.isADocument"
      :total="total"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :pagination-props="paginationProps"
      @query-change="loadData"
      :data="documentTemplatesByTemplateId"
      @sort-change="sortChange"
    >
      <el-table-column
        prop="document_status"
        label="Document Status"
        id="document_status"
        sortable="custom"
      >
        <template
          slot-scope="scope"
          v-if="scope.row && scope.row.document_status"
          style="font-size: 14px"
        >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'COMPLETED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Completed</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'VOIDED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Voided</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'SENT'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
          >
            Sent
          </span>
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'APPROVED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Approved</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'DECLINED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Declined</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'EXPIRED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Expired</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'DRAFT'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Draft</span
          >
          <span
            v-if="
              scope &&
              scope.row &&
              scope.row.document_status &&
              scope.row.document_status == 'ARCHIVED'
            "
            :class="scope.row.document_status.toLowerCase() + ' circle'"
            >Archived</span
          >
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="150">
        <template slot-scope="scope" v-if="scope.row">
          <ul>
            <div style="display: flex">
              <el-tooltip content="View Document" placement="top-start">
                <li>
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2"
                    @click="onView(scope.row._id)"
                  >
                    <i class="el-icon-view"></i>
                  </el-button>
                </li>
              </el-tooltip>
              <el-tooltip
                content="Resend Document"
                placement="top-start"
                v-if="
                  scope.row &&
                  scope.row.document_status &&
                  (scope.row.document_status == 'SENT' ||
                    scope.row.document_status == 'APPROVER') &&
                  !scope.row.document_filling_status &&
                  checkToOnlyResend(scope.row)
                "
              >
                <li style="margin-left: -3px">
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2"
                    @click="resendDocLink(scope.row)"
                  >
                    <i class="el-icon-position"></i>
                  </el-button>
                </li>
              </el-tooltip>
              <el-tooltip
                content="Download Document"
                placement="top-start"
                v-else-if="scope.row.document_status == 'COMPLETED'"
              >
                <li>
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2"
                    @click="onDownload(scope.row)"
                  >
                    <i class="el-icon-download"></i>
                  </el-button>
                </li>
              </el-tooltip>
              <li>
                <!-- *** need to work on it *** -->
                <el-dropdown>
                  <img
                    style="margin-left: -3px"
                    src="@/assets/img/icons/more-icon.svg"
                    alt="icon"
                    class="el-dropdown-link"
                  />
                  <el-dropdown-menu slot="dropdown">
                    <a
                      @click="alertCorrectedDoc(scope.row)"
                      v-if="
                        (checkPermissionForDocument(
                          scope.row,
                          'correctDocument'
                        ) &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'ARCHIVED' &&
                          scope.row.document_status != 'EXPIRED') ||
                        isfilledOrNot(scope.row)
                      "
                    >
                      <el-dropdown-item
                        ><i class="el-icon-document-checked"></i> Correct the
                        document</el-dropdown-item
                      >
                    </a>
                    <a
                      @click="EditVoidDocument(scope.row._id)"
                      v-if="
                        checkPermissionForDocument(
                          scope.row,
                          'cloneDocument'
                        ) &&
                        (scope.row.document_status == 'VOIDED' ||
                          scope.row.document_status == 'COMPLETED')
                      "
                    >
                      <el-dropdown-item
                        ><i class="el-icon-document-copy"></i>
                        Clone</el-dropdown-item
                      >
                    </a>
                    <a> </a>
                    <a
                      @click="alertvoided(scope.row)"
                      v-if="
                        checkPermissionForDocument(scope.row, 'voidDocument') &&
                        scope.row &&
                        scope.row.document_status &&
                        scope.row.document_status != 'VOIDED' &&
                        scope.row.document_status != 'DECLINED' &&
                        scope.row.document_status != 'COMPLETED' &&
                        scope.row.document_status != 'EXPIRED' &&
                        scope.row.document_status != 'ARCHIVED'
                      "
                    >
                      <el-dropdown-item>
                        <i class="el-icon-remove-outline"></i>Void Document
                      </el-dropdown-item>
                    </a>
                    <a
                      v-if="
                        checkPermissionForDocument(
                          scope.row,
                          'deleteDocument'
                        ) && scope.row.document_status != 'ARCHIVED'
                      "
                      @click="archiveDocument(scope.row)"
                    >
                      <el-dropdown-item
                        ><i class="el-icon-delete"></i>Delete</el-dropdown-item
                      >
                    </a>
                    <a
                      v-if="
                        scope.row &&
                        scope.row.document_status &&
                        scope.row.document_status === 'ARCHIVED'
                      "
                      @click="alertUndoDoc(scope.row)"
                    >
                      <el-dropdown-item>
                        <i class="el-icon-refresh-left"></i>Undo Delete
                      </el-dropdown-item>
                    </a>
                    <a
                      v-if="
                        scope.row &&
                        scope.row.document_status &&
                        scope.row.document_status === 'ARCHIVED'
                      "
                      @click="permanentDocument(scope.row)"
                    >
                      <el-dropdown-item>
                        <i class="el-icon-delete-solid"></i>Permanent Delete
                      </el-dropdown-item>
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- **** need to work on it *** -->
              </li>
            </div>
          </ul>
        </template>
      </el-table-column>
      <el-table-column type="expand">
        <template slot-scope="scope">
          <div class="expand-column-item">
            <div class="expand-column-item-label">Tracking Status:</div>
            <div class="expand-column-item-content">
              <p
                v-if="scope.row && scope.row.created_at"
                style="font-size: 14px"
              >
                {{ scope.row.created_at | globalDateTimeFormat }}
              </p>
            </div>
          </div>
          <div class="expand-column-item">
            <div class="expand-column-item-label">Sent On:</div>
            <div class="expand-column-item-content">
              <template
                v-if="
                  scope.row.document_tracking_status &&
                  scope.row.document_tracking_status.type
                "
              >
                <p
                  slot="reference"
                  style="font-family: inter; font-size: 14px"
                  class="text-secondary-sent"
                >
                  {{
                    scope.row.document_tracking_status.description
                      | truncate(18, "...")
                  }}
                </p>
              </template>
            </div>
          </div>
          <div class="expand-column-item">
            <div class="expand-column-item-label">Signed On:</div>
            <div class="expand-column-item-content">
              <template slot-scope="scope" v-if="scope.row">
                <p
                  v-if="
                    scope.row &&
                    scope.row.document_settings &&
                    scope.row.document_settings.life_cycle_date &&
                    scope.row.document_settings.life_cycle_date.signature_date
                  "
                  style="font-size: 14px"
                >
                  {{
                    scope.row.document_settings.life_cycle_date.signature_date
                      | globalDateFormat
                  }}
                </p>
                <p v-else style="text-align: center">- -</p>
              </template>
            </div>
          </div>
          <div class="expand-column-item">
            <div class="expand-column-item-label">Completed At:</div>
            <div class="expand-column-item-content">
              <template slot-scope="scope" v-if="scope.row">
                <p
                  v-if="scope.row && scope.row.completed_at"
                  style="font-size: 14px"
                >
                  {{ scope.row.completed_at | globalDateTimeFormat }}
                </p>
                <p v-else style="text-align: center">- -</p>
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="this.isADocument ? 'custom-pagination' : 'templatesPagination'"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="paginationProps"
      :page-size="pageSize"
      layout="prev, pager, next, jumper, sizes, total"
      :total="total"
    >
    </el-pagination>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import comanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import appConfig from "@/config/app";
//import draggable from "vuedraggable";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications.js";
export default {
  name: "templates-PreviewTemplate",
  /** this is a child component for preview page these props are used in this component */
  props: [
    "configurableId",
    "entityId",
    "entityDataIdForTable",
    "entityIdForTable",
    "isDocument",
    "this.getEntityDocumentsNotIncludingTemplatesData.total",
    "ignoreConfigurable",
    "showIsSingleTable",
    "configurableIds"
  ],
  /** ends here */
  mixins: [PermissionsHelper, NavigationHelper,comanyDocumentsPresignedURLsHelper],
  components: {
    // draggable,
  },
  data() {
    return {
      updateConfigId: "",
      radioValue: "never_renewal",
      entityDocumentTemplates: [],
      documentTemplates: [],
      employeeDocuments: [],
      step: 2,
      currentTab: {},
      eSignatureDocument: false,
      otherDocument: false,
      signatureDate: "",
      entityDocumentsNotIncludingTemplates: [],
      documentTemplatesByTemplateId: [],
      templates: [],
      authid: "",
      notifyDocumentRenewal: "",
      number: 1,
      activeName: "first",
      fileName: "",
      durationOfYears: "",
      configId: "",
      durationDate: "",
      expiraydate: null,
      isADocument: this.isDocument,
      order_type: null,
      configureLoading: false,
      total: 0,
      currentPage: 1,
      files: [],
      search_string: "",
      showDocument: false,
      pageSize: 5,
      pageVal: 5,
      page: 1,
      onTheDateValue: "",
      numberOfDuration: "",
      fields: [],
      currentFormBuilderWidth: null,
      order_by: null,
      totalDocs: null,
      loading: false,
      hasBackground: false,
      selected_page: [],
      LoadingText: "",
      uploadDocumentsInEntityProfile: false,
      docLoading: false,
      documentSettings: false,
      docSettingsLoading: false,
      backgroundImage: "",
      backgroundImages: [
        "https://devnimblehrms.s3.ap-south-1.amazonaws.com/company-document-backgrounds/60468b2c0a7b7942d5781c36/60468b2c0a7b7942d5781c36-0.png",
      ],

      durationValue: "",
      isUploadingInDocuments: false,
      num: "",
      notify_owner: true,
      fieldsFormUpload: {
        title: "",
        type: "CUSTOM",
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
      },
    };
  },
  async mounted() {
    this.configureLoading = true;
    this.LoadingText = "Please wait we are getting the things ready for you...";
    await this.fetchEntityDocumets();
    this.templateDocumentsSideMenu();
    if (this.isADocument) {
      await this.fetchEntityDocumentsNotIncludeTemplates();
    } else {
      this.entityDocumentTemplates = [];
      await this.fetchEntityDocumets();
    }
    await this.fetchEmployeeDocuments();
    // this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    this.authid =
      this.getAuthenticatedUser && this.getAuthenticatedUser._id
        ? this.getAuthenticatedUser._id
        : null;
    this.$store.dispatch("entities/fetchEntityById", {
      entity_id: this.$route.params.entity_id,
    });
    this.configureLoading = false;
    await this.checkUrlType();
    this.configureLoading = false;
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDataById",
      "getDocumentUploadToEntityProfileData",
      "getDocumentUploadToEntityProfileStatus",
      "getEntityDocumentsDataNotIncludingTemplatesStatus",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData",
      "getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdStatus",
    ]),
    ...mapGetters("fileUpload", [
      "getUploadFileDataStatus",
      "getUploadedPdfData",
      "getCopyDocumentStatus",
      "getCopyDocumentUrl",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getDefaultDateFormat"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    /** please don't change anything here it will be for future use*/
    ...mapGetters("documents", [
      "getResendDocumentStatus",
      "getSingleDocumentData",
      "getEmployeeDocumentsByConfigurableIdData",
      "getEmployeeDocumentsByConfigurableIdStatus",
      "getDocumentVoidStatus",
      "getDocumentCorrectStatus",
      "getCloneVoidDocStatus",
      "getCloneVoidDocData",
      "getArchiveDocumentStatus",
      "getDocumentCorrectErrors",
      "getCompanyDocumentAddStatus",
      "getNewCompanyDocument",
      "getCreateConfigureDocumentData",
      "getDraftDocumentCreateStatus",
      "getDraftDocumentCreateData",
      "getDocumentDeclineStatus",
      "getDocumentCompletedStatus",
      "getCompanyDocumentStatus",
      "getCompanyDocumentData",
      "getCompanyDocumentErrors",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    /** upto here */
    paginationProps() {
      return [5, 10, 20, 50];
    },
    basePdfdownloadUrl() {
      // return appConfig.JAVA_BASE_URL
      return appConfig.S3_BUCKET_URL;
    },
    getBackgroundImage() {
      return (path) => appConfig.S3_BUCKET_URL + "/" + path;
    },
    getClass() {
      return (data) => {
        this.currentTab = data;
        if (
          this.$route.query.documentTemplateId == data.configurable_document_id
        ) {
          return "list-group-item list-group-item-action active";
        } else {
          return "list-group-item list-group-item-action";
        }
      };
    },
  },
  methods: {
    currentTabTemplate(data) {
      this.currentTab = data;
    },
    handleChange(value) {
      console.log(value);
    },
    handleUploadFrom(e, fromSources) {
      const client = require("filestack-js").init("AOI3ejHzTcuVrrWTbhHeaz");
      const options = {
        fromSources: fromSources,
        accept: [
          "*.doc",
          "*.pdf",
          "*.docx",
          "*.txt",
          "*.png",
          "*.jpg",
          "*.jpeg",
        ],
        allowManualRetry: true,
        transformations: {
          crop: false,
        },
        customText: {
          "Select Files to Upload": "Select Image to Upload",
        },
        onUploadDone: (res) => this.handleUploadDoneFromOthers(res),
      };
      client.picker(options).open();
    },
    handleUploadDoneFromOthers(res) {
      if (
        res.filesUploaded.length > 0 &&
        res.filesUploaded[0].url !== undefined
      ) {
        this.uploadFromOthersBlobData = null;
        this.uploadFromOthersFileName = res.filesUploaded[0].filename;
        this.loading = true;
        var self = this;
        this.toDataUrl(res.filesUploaded[0].url, function (x) {
          self.uploadFromOthersBlobData = x;
          self.loading = false;

          self.files.push(
            new self.composeNewFileItem(
              new File(
                [self.uploadFromOthersBlobData],
                self.uploadFromOthersFileName
              )
            )
          );
        });
      }
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    prepareParams() {
      let params = {};
      params.file_upload_url = this.fileUploadUrl;
      params.file_upload_urls = this.fileUploadUrls;
      params.file_names = this.fileNames;
      params.title = this.fieldsFormUpload.selectedFileName;
      params.type = this.fieldsFormUpload.type;
      return params;
    },
    async addCompanyDocument() {
      try {
        this.loading = true;
        let params = this.prepareParams();

        if (!this.fileUploadUrl) {
          this.$message.error("Please select a document");
          return;
        }
        await this.$store.dispatch("documents/addCompanyDocument", params);
        if (this.getCompanyDocumentAddStatus) {
          await this.uploadSelectedDocument();
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    prepareDocumentUsers() {},
    async uploadSelectedDocument() {
      this.loading = true;
      this.loadingText = "Uploading Document...";
      if (this.getNewCompanyDocument) {
        await this.$store.dispatch(
          "documents/fetchConfigureDocumentIdByDocumentId",
          this.getNewCompanyDocument._id
        );
        if (this.getCreateConfigureDocumentData) {
          this.$notify.success({
            title: "Success",
            message: "Document created successfully",
          });
          await this.$store.dispatch(
            "configurableDocuments/fetchConfigurableDocumentById",
            {
              id: this.getCreateConfigureDocumentData.configurable_document._id,
            }
          );
        } else {
          this.$message("Sorry ! Error while creating configurable document");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
      this.loading = false;
    },
    resetUploadDocument() {
      this.documentSettings = false;
      this.signatureDate = "";
      this.durationValue = "";
      this.notifyDocumentRenewal = "";
      this.durationOfYears = "";
      this.onTheDateValue = "";
    },
    uploadUserDocument() {
      this.uploadDocumentsInEntityProfile = true;
    },
    uploadFile(file) {
      this.fileName = file.name;
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          file.name =
            file.name.includes(".") && file.name.split(".")[0]
              ? file.name.split(".")[0]
              : file.name;
          this.files.push(file);
        } else {
          this.$message.error("Large file.Maximum upload file size : 25 MB!");
        }
      } else {
        this.$message("File format is not supported");
      }
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    async uploadSingleFile() {
      try {
        this.docLoading = true;
        var formData = new FormData();
        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
          formData.append(
            "uploadedFiles",
            file.raw !== undefined ? file.raw : file
          );
        });

        let companyId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getAuthenticatedUser._id;
        let params = {
          data: formData,
          companyId: companyId,
        };
        await this.$store.dispatch("fileUpload/uploadFilesToConvertv4", params);
        this.docLoading = false;
        if (
          this.getUploadFileDataStatus &&
          this.getUploadFileDataStatus.length
        ) {
          let tempUrl = [];
          this.getUploadFileDataStatus.forEach((file) => {
            if (file.url.includes(appConfig.S3_BUCKET_URL)) {
              tempUrl.push(file.url.replace(appConfig.S3_BUCKET_URL, ""));
            } else {
              tempUrl.push(file.url);
            }
          });
          this.fileUploadUrl = tempUrl[0];
          this.fileUploadUrls = tempUrl;
          this.fileNames = this.files.flatMap((e) => e.name);
          this.disabledButton = false;
          this.isFileIsUploaded = true;
          setTimeout(() => {
            this.$notify.success({
              title: "Success",
              message: "Document Uploaded Successfully.",
            });
          }, 300);
          this.uploadDocumentsInEntityProfile = false;
          this.documentSettings = true;
          this.files = [];
          this.addCompanyDocument();
        } else {
          this.logoError = "Error in uploading file. Please try again..";
        }
      } catch (err) {
        this.loading = false;
      }
    },
    async templateDocumentsSideMenu() {
      this.entityDocumentsNotIncludingTemplates = [];
      this.documentTemplatesByTemplateId = [];
      this.configureLoading = true;
      this.LoadingText = "Fetching Document Templates...";
      let UrlPathView = "";
      if (this.getIframeUrlInfo != null) {
        UrlPathView = `/if/${this.getIframeUrlInfo._id}/entity/view/${
          this.$route.params.entity_id
            ? this.$route.params.entity_id
            : this.entityIdForTable
        }?dataId=${
          this.$route.params.entity_data_id
            ? this.$route.params.entity_id
            : this.entityDataIdForTable || ""
        }&activeTab=${this.$route.query.activeTab}&documentTemplateId=${
          this.updateConfigId !== "" ? this.updateConfigId : this.configurableId
        }&viewType=${this.$route.query.viewType}`;
      } else {
        UrlPathView = `/entity/view/${
          this.$route.params.entity_id
            ? this.$route.params.entity_id
            : this.entityIdForTable
        }?dataId=${
          this.$route.params.entity_data_id
            ? this.$route.params.entity_id
            : this.entityDataIdForTable || ""
        }&activeTab=${this.$route.query.activeTab}&documentTemplateId=${
          this.updateConfigId !== "" ? this.updateConfigId : this.configurableId
        }&viewType=${this.$route.query.viewType}`;
      }
      this.$router.push({
        path: UrlPathView,
        // `/entity/view/${
        //   this.$route.params.entity_id
        //     ? this.$route.params.entity_id
        //     : this.entityIdForTable
        // }?dataId=${
        //   this.$route.params.entity_data_id
        //     ? this.$route.params.entity_id
        //     : this.entityDataIdForTable || ""
        // }&activeTab=${this.$route.query.activeTab}&documentTemplateId=${
        //   this.configurableId
        // }`,
      });
      this.configId = this.configurableId;
      await this.fetchEmployeeDocuments();
      this.configureLoading = false;
    },
    handleSelectionChange(val) {
      this.selected_page = [];
      this.selected_page = val;
    },
    handleClickTab(tabValue) {
      console.log(tabValue);
    },
    handleClick(data, event) {
      if (event._props && event._props.label === data) {
        this.entityDocumentTemplates = [];
        this.templates = [];
        this.documentTemplatesByTemplateId = [];
        this.fetchEntityDocumets();
      } else {
        this.entityDocumentsNotIncludingTemplates = [];
        this.fetchEntityDocumentsNotIncludeTemplates();
      }
    },
    async alertUndoDoc(data) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to undo the deleted document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.archiveDocumentundo(data);
        });
      }
    },
    async permanentDocument(data) {
      let document_id = data._id;
      this.$confirm(
        "Are you sure you want to  'Permanently Delete' , Complete data of this document ?"
      )
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/parmanentdelete", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document Deleted Permanently");
            if (this.isADocument) {
              this.fetchEntityDocumentsNotIncludeTemplates();
            } else {
              this.fetchEmployeeDocuments();
            }
          } else {
            errorNotification("Error in Permanent document");
          }
        })
        .catch(() => {});
    },
    async archiveDocumentundo(data) {
      let document_id = data._id;
      this.loading = true;
      await this.$store.dispatch("documents/undoDocument", {
        document_id,
      });
      this.loading = false;
      if (this.getArchiveDocumentStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document Undo successfully",
        });
        if (this.isADocument) {
          this.fetchEntityDocumentsNotIncludeTemplates();
        } else {
          this.fetchEmployeeDocuments();
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in Undo document",
        });
      }
    },
    async archiveDocument(doc) {
      let document_id = doc._id;
      this.$confirm("Are you sure to delete the document?")
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/archiveDocument", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document deleted successfully");
            //this.fetchDocuments();
            if (this.isADocument) {
              this.fetchEntityDocumentsNotIncludeTemplates();
            } else {
              this.fetchEmployeeDocuments();
            }
          } else {
            errorNotification("Error in deleting document");
          }
        })
        .catch(() => {});
    },
    /** Documents API */
    async fetchEntityDocumentsNotIncludeTemplates() {
      try {
        this.isADocument = true;
        this.configureLoading = true;
        this.LoadingText = "Fetching Entity Documents...";
        let params = {
          limit: this.pageSize,
          page: this.page,
          entity_id: this.entityIdForTable,
          entity_data_id: this.entityDataIdForTable,
        };
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch(
          "entities/fetchEntitiesDocumentsNotIncludingTemplates",
          params
        );
        setTimeout(() => {
          this.configureLoading = false;
        }, 600);
        this.entityDocumentsNotIncludingTemplates =
          this.getEntityDocumentsNotIncludingTemplatesData &&
          this.getEntityDocumentsNotIncludingTemplatesData.data
            ? this.getEntityDocumentsNotIncludingTemplatesData.data
            : [];
        this.total =
          this.getEntityDocumentsNotIncludingTemplatesData &&
          this.getEntityDocumentsNotIncludingTemplatesData
            ? this.getEntityDocumentsNotIncludingTemplatesData.total
            : 0;
        this.currentPage = this.page;
      } catch (err) {
        console.log("fetchEntityDocumentsNotIncludeTemplates", err);
      }
    },
    /** ends here */
    async fetchEmployeeDocuments() {
      try {
        if (!this.entityDataIdForTable) return;
        this.configureLoading = true;
        this.LoadingText = "Fetching Employee Documents...";
        let params = {
          limit: this.pageSize,
          page: this.page,
          entity_id: this.entityIdForTable,
          entity_data_id: this.entityDataIdForTable,
        };
        if(!this.ignoreConfigurable && this.configurableId){
          params['configurable_document_id'] = this.configurableId;
        }
        if(this.configurableIds){
          params['configurable_document_ids'] = this.configurableIds;
        }
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (!this.configurableId || this.configurableId === undefined) {
          params.is_manually_uploaded = true;
        }
        await this.$store.dispatch(
          "entities/getEmployeeDocumentsByEntityIdAndConfigurableDocumentId",
          params
        );
        this.configureLoading = false;
        this.documentTemplatesByTemplateId =
          this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData &&
          this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData.data
            ? this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData
                .data
            : [];
        this.total =
          this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData &&
          this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData
            ? this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData
                .total
            : 0;
        this.currentPage = this.page;
      } catch (err) {
        this.configureLoading = false;
        console.log("fetchEmployeeDocuments", err);
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.isADocument) {
        this.fetchEntityDocumentsNotIncludeTemplates();
      } else {
        this.fetchEmployeeDocuments();
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page ? data.page : 1;
        if (this.isADocument) {
          this.configureLoading = true;
          this.loadingText = "Loading Documents...";
          await this.fetchEntityDocumentsNotIncludeTemplates();
          this.configureLoading = false;
        } else {
          this.configureLoading = true;
          this.loadingText = "Loading Templates...";
          await this.fetchEmployeeDocuments();
          this.configureLoading = false;
        }
      }
    },
    handleCurrentChange(val) {
      this.page = val ? val : 1;
      if (this.isADocument) {
        this.fetchEntityDocumentsNotIncludeTemplates();
      } else {
        this.fetchEmployeeDocuments();
      }
    },
    /** template documents api */
    async fetchEntityDocumets() {
      try {
        console.log("calling");
        this.configureLoading = true;
        this.LoadingText = "Fetching Entity Document Templates...";
        this.entityDocumentTemplates = [];
        this.entityDocumentsNotIncludingTemplates = [];
        this.isADocument = false;
        let params = {
          limit: this.pageVal,
          page: this.page,
          entity_id: this.entityIdForTable,
          entity_data_id: this.entityDataIdForTable,
        };
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch("entities/fetchEntitiesDocuments", params);
        if (this.configurableId) {
          await this.fetchEmployeeDocuments();
        }
        setTimeout(() => {
          this.configureLoading = false;
        }, 600);
        if (this.getEntityDocumentsDataStatus) {
          this.entityDocumentTemplates = this.getEntityDocumentsData;
          this.totalDocs = this.entityDocumentTemplates.length;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in fetching entity documents",
          });
        }
      } catch (err) {
        console.log("fetchEntityDocumets", err);
      }
    },
    /** ends here */
    async sortChange(a) {
      this.configureLoading = true;
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      if (this.isADocument) {
        await this.fetchEntityDocumentsNotIncludeTemplates();
      } else {
        await this.fetchEmployeeDocuments();
      }
      this.configureLoading = false;
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        if (this.isADocument) {
          await this.fetchEntityDocumentsNotIncludeTemplates();
        } else {
          await this.fetchEmployeeDocuments();
        }
      }
    },
    async alertvoided(data) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to Void the document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.DocumentVoid(data);
        });
      }
    },
    async DocumentVoid(data) {
      let document_id = data._id;
      await this.$store.dispatch("documents/voidDocument", { document_id });
      if (this.getDocumentVoidStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document voided successfully",
        });
        if (this.isADocument) {
          this.fetchEntityDocumentsNotIncludeTemplates();
        } else {
          this.fetchEmployeeDocuments();
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in void document",
        });
      }
    },
    onView(id) {
      let newWindow = open(
        `/emp-documents/custom/${id}`,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () {};
    },
    checkToOnlyResend(doc) {
      if (
        doc &&
        doc.document_users &&
        doc.document_users.length &&
        doc.document_users.length >= 1
      ) {
        let user = doc.document_users.forEach((x) => {
          if (
            x.sent_status &&
            !x.document_filling_status &&
            !x.is_cc &&
            x.type != "SENDER"
          ) {
            if (user) {
              return true;
            } else {
              return false;
            }
          }
        });
        return true;
      } else {
        false;
      }
    },
    async onDownload(data) {
      this.loading = true;
      this.loadingText = "Downloading the document, please wait...";
      let url = data?.document_completed_url
        ? data.document_completed_url
        : data?.configurable_document_data?.document_upload_url;
      let docName = data.name || "Document";
      if (url) {
        await this.downloadFilesFromS3Paths([url], [docName]);
      }
      this.loading = false;
      this.loadingText = "";
    },
    async EditVoidDocument(id) {
      try {
        await this.$store.dispatch("documents/EditVoidDocument", id);
        if (this.getCloneVoidDocStatus && this.getCloneVoidDocData) {
          let new_id = this.getCloneVoidDocData.data._id;
          successNotification("Document cloned successfully");
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: new_id,
            },
          });
        } else {
          this.$notify({
            title: "Error",
            message: "Error in cloning document",
            type: "error",
          });
        }
      } catch (err) {
        console.log("EditVoidDocument", err);
      }
    },
    async alertCorrectedDoc(doc) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to correct the document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.correctDocument(doc);
        });
      }
    },
    async correctDocument(doc) {
      try {
        let document_id = doc._id;
        await this.$store.dispatch("documents/correctDocument", document_id);
        if (this.getDocumentCorrectStatus) {
          successNotification("Document is corrected successfully");
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: document_id,
            },
          });
        } else {
          let error_message = this.getDocumentCorrectErrors
            ? this.getDocumentCorrectErrors
            : "Error while correcting the document";
          this.$notify.error({
            title: "Error",
            message: error_message,
          });
          this.$emit("reloadDashboardStats", true);
          if (this.isADocument) {
            this.fetchEntityDocumentsNotIncludeTemplates();
          } else {
            this.fetchEmployeeDocuments();
          }
        }
      } catch (err) {
        console.log("correctDocument", err);
      }
    },
    resetData() {
      this.page = 1;
      this.fetchEmployeeDocuments();
    },
    isfilledOrNot(doc) {
      if (
        doc &&
        doc.document_status &&
        doc.configurable_document_data &&
        doc.configurable_document_data[0]
      ) {
        let fields = doc.configurable_document_data.pages[0].fields.filter(
          (el) => {
            if (
              el.filled_by &&
              el.filled_by != "SENDER" &&
              el.type != "my-signature" &&
              el.type != "MY_INITIAL"
            ) {
              return el;
            }
          }
        );
        let fields_data = doc.document_data;
        let filled_fields = fields.filter((el) => {
          return fields_data[el.key].length;
        });
        if (
          filled_fields.length &&
          doc.document_status != "DECLINED" &&
          doc.document_status != "COMPLETED" &&
          doc.document_status != "ARCHIVED"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    async resendDocLink(doc) {
      let sendinguser = doc.document_users.find((x) => {
        return (
          x.sent_status &&
          !x.document_filling_status &&
          !x.is_cc &&
          x.type != "SENDER"
        );
      });
      try {
        let params = {
          document_id: doc._id,
          contact_id: sendinguser.contact_id
            ? sendinguser.contact_id
            : sendinguser.contact_id._id,
        };
        this.esignLoading = true;
        await this.$store.dispatch(
          "documents/resendDocumentLinkToContact",
          params
        );
        this.esignLoading = false;
        if (this.getResendDocumentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document Link sent successfully",
          });
        }
      } catch (err) {
        this.esignLoading = false;
        console.log("resendDocLink", err);
      }
    },
  },
  watch: {
    configurableId(newVal) {
      this.updateConfigId = newVal;
      if (this.$route.query.documentTemplateId !== newVal) {
        this.$router.push({
          query: { ...this.$route.query, documentTemplateId: newVal },
        });
      }
    },
    search_string: function (val) {
      this.search_string = val;
      if (this.isADocument) {
        this.fetchEntityDocumentsNotIncludeTemplates();
      } else {
        this.fetchEntityDocumets();
      }
    },

    "$route.query.documentTemplateId"(step) {
      console.log(step);
      this.documentTemplateId =
        this.updateConfigId !== ""
          ? this.updateConfigId
          : parseInt(step)
          ? parseInt(step)
          : 1;
      this.resetData();
    },
    "$route.query.step"(step) {
      this.step = parseInt(step);
    },
    "$route.query.key"(key) {
      this.key = key ? key : "templates";
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-file-el-upload {
  @media (max-width: 991.98px) {
    .el-upload-dragger {
      height: 207px !important;
    }
  }
  .el-upload-dragger {
    border: none;
    width: 360px;
    margin-top: 52px;
  }
}
.documentsTable {
  width: 100%;
  float: right;
  margin-top: 10px;
}
.my-header {
  background: blue !important; // !important needed here to override default style
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="templates-table-"] {
    width: 60%;
  }
}
// .templates-table {
//   width: 58%;
//   float: right;
//   margin-top: -350px;
//   @media screen and (max-width: 1300px) {
//     width: 75%;
//   }
//   @media screen and (max-width: 1200px) {
//     width: 70%;
//   }
//   @media screen and (max-width: 1400px) {
//     width: 70%;
//   }
//   @media screen and (max-width: 1600px) {
//     width: 81%;
//   }
// }
.templates-table-is-mobile {
  width: 100%;
  // float: right;
}
.stySideMenu {
  margin-left: 10%;
}
.custom-pagination {
  margin-left: 31%;
  margin-top: 29%;
}
.templatesPagination {
  margin-left: 20%;
  margin-top: 3%;
}
.sty {
  display: flex;
}
.sidemenu {
  height: 460px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-bottom: 2px solid #f5f5f5;
  border-right: 1px ridge;
  border-left: 2px solid #f5f5f5;
  border-top: 2px solid #f5f5f5;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.list-group {
  margin-top: 15px;
  width: 255px;
  border-left: 2px #9299b8;
  border-top: 2px #9299b8;
  border-bottom: 1px ridge;
  .list-group-item {
    margin-right: -20px;
    margin-left: -35px;
    padding: 0em;
    border: none;
    color: #9299b8;
    &.active {
      color: #f754a2;
      border-left: 3px solid #f754a2;
    }
  }
}
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  &::after {
    background: #635e5e;
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }
  &.completed::after {
    background: #08ad36;
  }
  &.draft::after {
    background: #f19203;
  }
  &.sent::after {
    background: #2f80ed;
  }
  &.active::after {
    background: #635e5e;
  }
  &.expired::after {
    background: #f03232;
  }
}
.name-types {
  margin-top: 2%;
  .filename {
    height: 56px;
    margin-top: 1%;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dfe3eb;
    box-sizing: border-box;
    border-radius: 2px;
    .el-upload-list__item-name {
      margin-top: 1em;
      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }
    .direction-buttons {
      display: flex;
      visibility: visible;
      position: relative;
      top: -35%;
      left: 97%;
      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }
    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
    }
  }
}

.select-your-preference-upload-file {
  display: flex;
  justify-content: center;
  .upload-file-container {
    &:hover {
      border: 1px solid #f754a2;
    }
    @media (min-width: 991.98px) {
      min-height: 336px;
    }
    // max-width: 360px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .upload-file-el-upload {
      .block-types {
        .upload-file {
          width: 300px;
          height: 100%;
        }
      }
      .upload-a-file-bottom-text {
        border-bottom: 2px solid #ddd3d3;
        padding: 0 0 10px 0;
        margin: 0px 10px;
      }
      @media (max-width: 991.98px) {
        .el-upload-dragger {
          height: 207px !important;
        }
      }
      .el-upload-dragger {
        border: none;
        width: 300px;
        margin-top: 40px;
      }
    }
    .upload-from-other-container {
      text-align: center;
      .el-upload {
        border: none !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border: none;
        }
      }
      .upload-a-file-upload-from-text {
        margin-top: -30px;
      }
      .from-others {
        display: flex;
        justify-content: center;
        .image {
          cursor: pointer;
          margin-left: 10px;
          margin-right: 10px;
          .upload-icon {
            width: 25px;
            height: 25px;
          }
          @media (max-width: 991.98px) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
</style>
