var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.laoading),expression:"laoading"}],attrs:{"element-loading-spinner":"el-icon-loading","element-loading-text":_vm.loadingText}},[_c('el-row',{attrs:{"gutter":20}},_vm._l((_vm.fields),function(field,index){return _c('el-col',{key:index,attrs:{"span":7}},[_c('el-form',{attrs:{"model":_vm.row}},[_c('el-form-item',{attrs:{"label":field.label,"prop":field.key,"rules":[
            {
              required: field.isRequired,
              message: `${field.label} is required`,
              trigger: 'blur',
            },
          ]}},[(field.type == 'text')?_c('el-input',{attrs:{"disabled":field.isDisabled,"placeholder":'Enter ' + field.label},model:{value:(_vm.row[field.key]),callback:function ($$v) {_vm.$set(_vm.row, field.key, $$v)},expression:"row[field.key]"}}):(field.type == 'select')?_c('el-select',{attrs:{"placeholder":'Select ' + field.label},model:{value:(_vm.row[field.key]),callback:function ($$v) {_vm.$set(_vm.row, field.key, $$v)},expression:"row[field.key]"}},_vm._l((field.values),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1):(field.type == 'dateTime')?_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"Select date and time","disabled":field.isDisabled},model:{value:(_vm.row[field.key]),callback:function ($$v) {_vm.$set(_vm.row, field.key, $$v)},expression:"row[field.key]"}}):_vm._e()],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }