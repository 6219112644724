<template>
  <div>
    <!-- Input to choose the number of slides -->
    <div>
      <el-form label-position="top">
        <el-form-item label="Number of Slides">
          <el-input
            type="number"
            v-model="numSlides"
            min="1"
            placeholder="Enter number of slides"
            @change="updateSlides(numSlides)"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <!-- Accordion to render each form for a card -->
    <el-collapse v-model="activeSlides">
      <el-collapse-item
        v-for="(slide, index) in slidesData"
        :key="index"
        :name="index"
      >
        <template #title>Slide {{ index + 1 }}</template>

        <!-- Title Input -->
        <el-form label-position="top">
          <el-form-item label="Title">
            <el-input
              v-model="slide.title"
              @input="updateCarouselCards"
              placeholder="Enter title"
            ></el-input>
          </el-form-item>

          <!-- Description Input -->
          <el-form-item label="Description">
            <el-input
              type="textarea"
              v-model="slide.description"
              @input="updateCarouselCards"
              placeholder="Enter description"
            ></el-input>
          </el-form-item>

          <!-- Button Name Input -->
          <el-form-item label="Button Name">
            <el-input
              v-model="slide.buttonName"
              @input="updateCarouselCards"
              placeholder="Enter button name"
            ></el-input>
          </el-form-item>

          <!-- Image Cropper and Uploader -->
          <el-form-item label="Upload Image">
            <el-button @click.prevent="showCropperModal(index)"
              >Upload/Edit Image</el-button
            >
            <div v-if="slide.imageUrl">
              <p>Uploaded Image URL:</p>
              <a :href="slide.imageUrl" target="_blank">{{ slide.imageUrl }}</a>
            </div>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <!-- Cropper Modal -->
    <dialog-component
      :title="'Update picture'"
      :visible="outerVisible"
      :width="getIsMobile ? '100%' : '100%'"
      :height="getIsMobile ? '100%' : '100%'"
      @before-close="outerVisible = false"
      :style="{ top: getIsMobile ? '0' : '10vh' }"
    >
      <vue-anka-cropper
        class="upload-file mt-1 mb-1"
        :options="{
          aspectRatio: false,
          closeOnSave: true,
          cropArea: 'box',
          croppedHeight: 1000,
          croppedWidth: 1000,
          cropperHeight: 400,
          dropareaMessage: ' Drag & Drop  file here or use the button below.',
          frameLineDash: [1, 1000],
          frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
          handleFillColor: 'rgba(255, 255, 255, 0.2)',
          handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
          handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
          handleSize: 5,
          handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
          layoutBreakpoint: 40,
          maxCropperHeight: 1000,
          maxFileSize: 8000000,
          overlayFill: 'rgba(0, 0, 0, 0.5)',
          previewOnDrag: true,
          previewQuality: 0.65,
          resultQuality: 0.8,
          resultMimeType: 'image/jpeg',
          selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',
          showPreview: true,
          skin: 'light',
          uploadData: {},
          uploadTo: false,
        }"
        name="logo"
        @cropper-error="fileError"
        @cropper-file-selected="insertImage"
        @cropper-preview="setImageData"
        @cropper-saved="setImageData"
        v-loading="upLoadEffect"
        accept="image/jpeg,image/png,image/jpg,image/gif"
      ></vue-anka-cropper>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCropperModal">Cancel</el-button>
        <el-button type="primary" @click="saveCroppedImage">Save</el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import { mapActions } from "vuex"; // Import mapActions to simplify Vuex action calls
import { mapGetters } from "vuex";

export default {
  components: {
    vueAnkaCropper,
  },
  props: ["field"],
  mounted() {
    this.slidesData = this.field.carousel_cards;
    this.numSlides = this.slidesData.length;
  },
  data() {
    return {
      numSlides: 0,
      slidesData: [],
      activeSlides: [],
      croppedImageBlob: null,
      outerVisible: false,
      selectedSlideIndex: null,
      upLoadEffect: false,
    };
  },
  computed: {
    ...mapGetters("s3FileUpload", ["getFileUploadData", "getFileUploadStatus"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  },
  methods: {
    ...mapActions(["s3FileUpload/uploadFileToS3"]), // Use mapActions to bind Vuex action

    fileError() {
      this.$alert("File Supported: JPG, PNG, JPEG, GIF");
    },

    updateSlides(newVal) {
      this.slidesData = [];
      this.activeSlides = [];

      for (let i = 0; i < newVal; i++) {
        this.slidesData.push({
          title: "",
          description: "",
          buttonName: "",
          imageUrl: "",
        });
        this.activeSlides.push(i);
      }

      this.updateCarouselCards();
    },

    updateCarouselCards() {
      this.$set(this.field, "carousel_cards", [...this.slidesData]);
    },

    showCropperModal(index) {
      this.selectedSlideIndex = index;
      this.outerVisible = true;
    },

    closeCropperModal() {
      this.outerVisible = false;
      this.croppedImageBlob = null;
    },

    insertImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.croppedImageBlob = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },

    setImageData(file) {
      this.croppedImageBlob = file;
    },

    async saveCroppedImage() {
      if (!this.croppedImageBlob) {
        alert("Please crop the image before uploading.");
        return;
      }

      this.upLoadEffect = true; // Show loading effect

      try {
        const formData = new FormData();
        const fileName = `slide_image_${
          this.selectedSlideIndex + 1
        }_${Date.now()}.jpg`;

        // Create a blob from the cropped image if it's a URL
        const blob =
          typeof this.croppedImageBlob === "string"
            ? await fetch(this.croppedImageBlob).then((r) => r.blob())
            : this.croppedImageBlob; // If it's already a Blob or File

        formData.append("files", blob, fileName);

        // Dispatch the upload action
        await this.$store.dispatch("s3FileUpload/uploadFileToS3", formData);

        // Assuming your Vuex action sets the uploaded image URL in the store
        const uploadedImageUrl = this.getFileUploadData; // Adjust this based on your Vuex store structure

        if (uploadedImageUrl) {
          // Update the slidesData with the actual image URL from the store
          this.slidesData[this.selectedSlideIndex].imageUrl = uploadedImageUrl;

          // Update the carousel cards with the new image URL
          this.updateCarouselCards();
        } else {
          alert("Error: Unable to retrieve the uploaded image URL.");
        }

        // Close the modal
        this.closeCropperModal();
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("An error occurred while uploading the image. Please try again.");
      } finally {
        this.upLoadEffect = false; // Hide loading effect
      }
    },
  },
};
</script>

<style scoped>
.el-collapse-item__header {
  font-weight: bold;
}
</style>
