<template>
  <section>
    <div v-if="!checkEmailTemplateExists()">Please Select Template</div>
    <div v-else>
      <div class="size-selector" v-if="!showFields.emailView">
        <label for="cardHeight">Card Height:</label>
        <input
          type="range"
          id="cardHeight"
          min="100"
          max="700"
          v-model="cardHeight"
        />
        <span>{{ cardHeight }}px</span>
      </div>
      <el-row
        style="display: flex; flex-direction: row; margin-top: 10px"
        class="container"
      >
        <!-- First Block: Display all templates -->
        <el-col :span="4" style="overflow-y: auto" class="sidebar">
          <!-- <el-button type="primary" plain size="large" @click="selectAll">
  {{ selectedRowsIds.length === (searchQuery ? searchData.length : firstBlockData.length) ? 'Deselect All' : 'Select All' }}
</el-button> -->

          <el-scrollbar wrap-style="min-height: 70vh;overflow-y: auto;">
            <div class="card-summary">
              <div
                v-for="(template, index) in !searchQuery
                  ? parsedData
                  : this.searchData"
                :key="index"
                class="summary"
                :style="{
                  backgroundColor:
                    selectedRecord._id === template._id ||
                    isSelectedInBulk(template._id)
                      ? '#f5f7fa'
                      : 'white',

                  color:
                    selectedRecord._id === template._id ||
                    isSelectedInBulk(template._id)
                      ? 'var(--primary-color)'
                      : '',

                  borderLeft:
                    selectedRecord._id === template._id
                      ? '3px solid var(--primary-color)'
                      : '',
                }"
              >
                <div>
                  <el-checkbox
                    :value="isSelectedInBulk(template._id)"
                    @change="onCheckboxChange(template, $event)"
                    style="float: left; color: var(--primary-color)"
                  ></el-checkbox>
                </div>
                <div class="ml-2" @click="selectedCard(template)">
                  <div v-if="template.field === 'title'">
                    <span>Title:</span>{{ template.fieldValue }}
                  </div>
                  <div v-else-if="template.field === 'description'">
                    <span>Description:</span>{{ template.fieldValue }}
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        getFieldKey('title') && getFieldValue('title', template)
                      "
                      :style="
                        getFieldInStepFormat(getFieldKey('title', template))
                      "
                    >
                      <span class="bold-key">{{ getFieldKey("title") }} </span>:
                      <span class="card-data">{{
                        getFieldValue("title", template) | truncate(140, "...")
                      }}</span>
                    </div>
                    <div
                      v-if="
                        getFieldKey('description') &&
                        getFieldValue('description', template)
                      "
                      :style="
                        getFieldInStepFormat(
                          getFieldKey('description', template)
                        )
                      "
                    >
                      <span class="bold-key"
                        >{{ getFieldKey("description") }} </span
                      >:
                      <span class="card-data">{{
                        getFieldValue("description", template)
                          | truncate(140, "...")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </el-col>
        <!-- Second Block: Display selected  template details -->
        <el-col :span="6" style="max-height: 60vh">
          <div style="overflow-y: hidden">
            <el-scrollbar wrap-style="max-height: calc(100vh - 180px)">
              <div
                class="cards"
                v-if="
                  selectedRecord &&
                  (searchQuery != '' || middleDivFilterData.length)
                "
              >
                <div
                  v-for="(row, index) in searchQuery != ''
                    ? searchFilterData
                    : middleDivFilterData"
                  :key="index"
                  style="overflow-x: hidden"
                  class="entity-card-data"
                >
                  <div
                    v-if="showFields.multiselectDisplay"
                    class="entity-data-card"
                    @dblclick="cellDoubleClicked(row)"
                  ><div >
                    <div v-if="!isEdit">
                        <i class="tick-icon" style="float: right;" @click="saveData" v-if="isDragging">✔️</i>
                      <draggable
                        v-model="showFields.multiselectDisplay"
                        @start="isDragging = true"
                        @end="isDragging = false" 
                      >
                     
                        <div
                          v-for="(data, index) in showFields.multiselectDisplay"
                          :key="index"
                          class="no-edit"
                        >
                            <el-tooltip content=" You can drag the fields to re-arrange the order" placement="top">
                              <div class="icon-container">
                              <img
                              src="@/assets/draggable.svg"
                              alt="draggable-icon"
                              class="draggable-icon"
                            />
                            </div>
                             </el-tooltip>
                            <div class="bold-key key-field">{{ getFieldLabel(data.split("#")[1]) }}:</div>
                          <div
                            v-for="field in filterData(row)"
                            :key="field._id"
                            class="value-field"
                          >
                            <div v-if="field.key === data">
                              <span v-if="field.inputType === 'ENTITY'">
                                <p
                                  v-if="
                                    row[
                                      field.key.split('#')[0] +
                                        '#' +
                                        field.key.split('#')[1]
                                    ] &&
                                    row[
                                      field.key.split('#')[0] +
                                        '#' +
                                        field.key.split('#')[1]
                                    ].length > 0
                                  "
                                >
                                  {{
                                    currentData[field.key.split("#")[0]][
                                      `${field.key.split("#")[1]}/name`
                                    ]
                                  }}
                                </p>
                                <p v-else>-----</p>
                                </span
                              >
                              <span v-else-if="currentData[field.key.split('#')[0]][field.key.split('#')[1]] && field.inputType != 'ENTITY'">
                                      {{ currentData[field.key.split('#')[0]][field.key.split('#')[1]] }}
                                    </span>
                              <span v-else>
                                 ----
                              </span>
                            </div>
                          </div>
                        </div>
                        
                      </draggable>
                    </div>
                    <div v-else-if="isEdit">
                      <div class="edit-cancel-div" v-if="isEdit">
                        <span class="edit-icons">
                          <i class="tick-icon" @click="doubleClickUpdate(row)"
                            >✔️</i
                          >
                          <i class="cross-icon" @click="cancelEditAll">❌</i>
                        </span>
                      </div>
                      <div
                        v-for="field in filterData(row)"
                        :key="field.id"
                        class="second-card "
                      >
                        <span class="bold-key second-card-key"
                          >{{ field.label.split("-")[1] }}:</span
                        >  
                        <div>
                          <span
                            v-if="field.inputType === 'AUTO_INCREMENT_NUMBER'"
                            >{{
                              currentData[field.key.split("#")[0]][
                                field.key.split("#")[1]
                              ]
                            }}</span
                          >
                          <input
                            v-if="
                              field.inputType !== 'SELECT' &&
                              field.inputType !== 'DATE_TIME' &&
                              field.inputType !== 'ENTITY' &&
                              field.inputType !== 'AUTO_INCREMENT_NUMBER' &&
                              field.inputType !== 'CHECKBOX'
                            "
                            v-model="
                              currentData[field.key.split('#')[0]][
                                field.key.split('#')[1]
                              ]
                            "
                            class="edit-input"
                          />
                          <el-select
                            v-if="field.inputType === 'SELECT'"
                            v-model="
                              currentData[field.key.split('#')[0]][
                                field.key.split('#')[1]
                              ]
                            "
                            size="small"
                            style="margin-left: 4px"
                          >
                            <el-option
                              v-for="option in field.options"
                              :value="option"
                              :key="option"
                            >
                              {{ option }}
                            </el-option>
                          </el-select>
                          <el-date-picker
                            v-if="
                              field.inputType === 'DATE_TIME' && isCancelEditAll
                            "
                            style="margin-left: 4px;width: 170px;"
                            class="second-card"
                            v-model="
                              currentData[field.key.split('#')[0]][
                                field.key.split('#')[1]
                              ]
                            "
                            type="datetime"
                            placeholder="Pick a date and time"
                            format="dd-MM-yyyy HH:mm:ss"
                            value-format="dd-MM-yyyy HH:mm:ss"
                          />
                          <EntityExecute
                            v-if="field.inputType === 'ENTITY'"
                            :data="
                              JSON.parse(
                                JSON.stringify({
                                  ...field,
                                  key: field.key.split('#')[1],
                                })
                              )
                            "
                            :form="currentData[field.key.split('#')[0]]"
                            :is-view="false"
                            :hasLabel="false"
                            :entityDataExists="true"
                            :fieldsData="[]"
                          ></EntityExecute>
                          <div v-if="field.inputType === 'CHECKBOX'">
                            <el-checkbox
                              v-model="
                                currentData[field.key.split('#')[0]][
                                  field.key.split('#')[1]
                                ]
                              "
                              :disabled="
                                quickUpdateFieldDisabled(
                                  field.key.split('#')[0],
                                  field.key.split('#')[1],
                                  currentEntity
                                )
                              "
                            ></el-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <br />
                    <div style="margin-top:20px;">
                    <template
                      v-if="
                        showFields.action_group_button &&
                        showFields.action_group_button.length &&
                        !isEdit
                      "
                    >
                      <div
                        v-for="(
                          action, index
                        ) in showFields.action_group_button"
                        :key="index"
                      >
                        <template v-if="hasHash(action)">
                          <action-button-execute
                            style="margin-top: 10px"
                            :data="{
                              ...getActionButtonField(action),
                              parent_entityDataId: row._id,
                            }"
                            :form="row.entityData[action.split('#')[0]]"
                            :is-view="false"
                            :hideStyles="true"
                            :isView="false"
                            :workflowDataId="row._id"
                            :label="getActionButtonField(action, row._id).label"
                            :entity_type="currentEntity.entity_type"
                            :entityDataId="row._id"
                            :entityId="currentEntity._id"
                            :entityData="row"
                            :checkIsDisabled="false"
                            :selectedTableRows="[]"
                            :fieldsData="
                              getCurrentTemplateFields(action.split('#')[0])
                            "
                            :selfTemplateId="action.split('#')[0]"
                            @actionExecuted="refreshCurrentPage"
                          ></action-button-execute>
                        </template>
                        <template v-else>
                          <a
                            v-if="
                              IframePermissions('VIEW', 'EntityGalleryView')
                            "
                            href="javascript:void(0);"
                            class="card-link"
                            @click="viewWorkUserData(row, action)"
                            style="
                              color: rgb(64, 158, 255);
                              text-decoration: none;
                              padding: 10px;
                              margin-left: 120px;
                              margin-bottom: 5px;
                            "
                          >
                            {{ action }}
                          </a>
                        </template>
                      </div>
                    </template>
                    <template
                      v-if="
                        !showFields.action_group_button ||
                        !showFields.action_group_button.length &&
                         !isEdit
                      "
                    >
                      <el-link
                        v-if="IframePermissions('VIEW', 'EntityGalleryView')"
                        type="primary"
                        :underline="false"
                        class="card-link"
                        style="margin-left: 100px; margin-bottom: 10px;"
                        @click="viewWorkUserData(row)"
                      >
                        View details
                      </el-link>
                    </template>
                  </div>
                </div>
                  <el-empty
                    v-else
                    :image-size="100"
                    description="Not configured"
                    class="entity-data-card"
                  ></el-empty>
                </div>
              </div>
              <div v-else>
                <el-empty description="No data"></el-empty>
              </div>
              <div
                class="cards"
                v-if="
                  Array.isArray(searchFilterData) &&
                  searchFilterData.length === 0 &&
                  searchQuery
                "
              >
                <el-empty description="No data"></el-empty>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
        <!-- Third Block: Display selected email template content in full view -->
        <el-col style="width: 49vw; margin-right: 10px">
          <div
            v-for="(row, index) in searchQuery != ''
              ? searchFilterData
              : middleDivFilterData"
            :key="index"
            :span="14"
            style="
              max-height: 70vh;
              overflow-y: scroll;
              overflow-x: hidden;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              transition: transform 0.2s, box-shadow 0.2s;
              border: 1px solid slategray;
            "
            class="card-container p-1"
          >
            <div
              class="bold-key"
              style="margin: 4px; font-size: 20px; float: left; max-width: 73%"
            >
              {{
                row.entityData[row.templates_data?.[0]?.template_id]?.subject
              }}
            </div>
            <div
              v-if="
                actionButtonFieldKeys &&
                actionButtonFieldKeys.length &&
                isRelationalDataFetched
              "
            >
              <div
                v-for="actionKey in actionButtonFieldKeys"
                :key="actionKey"
                class="button-container pb-1"
              >
                <ActionButtonExecute
                  v-if="
                    row.entityData &&
                    actionKey.split('#') &&
                    actionKey.split('#').length
                  "
                  :data="getActionButtonField(actionKey, row._id)"
                  :form="row.entityData[actionKey.split('#')[0]]"
                  :is-view="false"
                  :isView="false"
                  :workflowDataId="row._id"
                  :label="getActionButtonField(actionKey, row._id).label"
                  :entity_type="currentEntity.entity_type"
                  :entityDataId="row._id"
                  :entityId="currentEntity._id"
                  :entityData="row"
                  :checkIsDisabled="false"
                  :selectedTableRows="[]"
                  :fieldsData="
                    getCurrentTemplateFields(actionKey.split('#')[0])
                  "
                  :selfTemplateId="actionKey.split('#')[0]"
                  templateName="templateName"
                  @actionExecuted="refreshCurrentPage"
                />
              </div>
            </div>
            <el-scrollbar wrap-style="max-height: calc(100vh - 180px)">
              <div :key="row._id" class="card" v-if="getParsedData(row._id)">
                <p v-html="getParsedData(row._id)"></p>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import EntityHelper from "@/mixins/EntitiesHelper";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import ActionButtonExecute from "../../templates/formComponentsExecute/ActionButtonExecute.vue";
export default {
  name: "EntityEmailView",
  data() {
    return {
      isdragging:false,
      fieldsData: [],
      form: {},
      key: "",
      isEdit: false,
      currentData: {},
      multiSelectValues: {},
      selectedRecords: [],
      isCancelEditAll: false,
      stylesObject: {},
      myDate: "",
      result: [],
      searchTerm: "",
      searchData: [],
      searchQuery: "",
      isSearch: false,
      filteredData: [],
      loading: false,
      totalData: [],
      limit: 10,
      filterColumList: [],
      initialPage: true,
      selectedEmailTemp: "",
      total: 0,
      entityRecords: [],
      offset: 0,
      data: [],
      entityFiltersData: {
        query_type: "AND",
        filters: [],
        filter_name: "",
        entity_id: "",
        isSaveFilter: false,
        columns_settings: [],
        quick_filters: [],
      },
      isSelect: false,
      applied_quick_filters: [],
      currentPage: 1,
      pageSize: 10,
      options: {},
      UpdatedData: {},
      fetchData: [],
      updatedKeyValueData: {},
      quickUpdateIndex: -1,
      selectedEntityFields: [],
      entity_id: "",
      emailFields: [],
      htmlStrings: [
        "<div class='content'>HTML Content 1. This is a long content block that will overflow and require scrolling within the card. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum et ligula in nunc bibendum fringilla a eu lectus.</div>",
        "<div class='content'>HTML Content 2. This content is not too long.</div>",
        "<div class='content'>HTML Content 3. Here is some more text that will need scrolling if the card height is too small.</div>",
        "<div class='content'>HTML Content 4. Short content.</div>",
        "<div class='content'>HTML Content 5. Another block of text that might overflow depending on the height setting. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>",
      ],
      cardWidth: 30,
      selectedRecord: {},
      cardHeight: 300,
      selectedEmailTemplate: {},
      url_key: "",
      copyModal: false,
    };
  },
  components: {
    ActionButtonExecute,
    draggable,
    EntityExecute: () =>
      import("@/components/templates/formComponentsExecute/EntityExecute.vue"),
  },
  props: [
    "selectedRowsIds",
    "entityId",
    "search_string",
    "showFields",
    "currentMenu",
    "isApplicationUserSideValid",
    "getSelfEntityFilters",
    "emailTemplates",
    "keyLabelData",
    "updateRow",
    "currentEntity",
    "quickUpdateRowData",
    "actionButtonFieldKeys",
    "parsedData",
    "isRelationalDataFetched",
    "allFieldsObj",
  ],
  mixins: [
    TemplateBuilderhelper,
    NavigationHelper,
    CustomDashboardConfig,
    EntityHelper,
  ],
  mounted() {
    let limit =
      this.pageSizes && this.pageSizes[0]
        ? this.pageSizes[0]
        : this.getPageSize;
    this.pageSize = limit;
    this.refreshPage = true;
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.pageSize = parseInt(this.$route.query.pageSize) || 10;
    this.UpdatedData = this.quickUpdateRowData;
    this.entity_id = this.$route.params.entity_id || this.entityId;
    this.selectedRecord = this.parsedData?.[0];
    this.handleStyles();
    this.setStylesForData();
  },
  created() {
    this.myDate = new Date();
  },

  methods: {
    setStylesForData() {
      this.parsedData.map((row) => {
        this.showFields?.multiselectDisplay.map((fKey) => {
          this.getFieldInStepFormat(fKey, row);
        });
      })},
    getFieldLabel(key) {
      let firstObjectKey = Object.keys(this.keyLabelData)[0];
      return this.keyLabelData[firstObjectKey][key];
    },
    saveData() {
      this.showFields.multiselectDisplay = [
        ...this.showFields.multiselectDisplay,
      ];
      this.$emit("SaveData");
      this.$notify({
        title: "Success",
        message: "Field order has been changed successfully!",
        type: "success",
      });
    },
    handleEmptyInput(field) {
      const fieldKey = field.key.split("#")[1];
      const fieldGroup = field.key.split("#")[0];
      // Check if the input value is empty
      if (!this.currentData[fieldGroup][fieldKey]) {
        this.currentData[fieldGroup][fieldKey] = null; // Set it to null if empty
      }
    },
    getFieldInStepFormat(key, data) {
      let p = this.showFields?.multiselectDisplay?.includes(key);
      if (key && p && key in this.allFieldsObj) {
        if (this.stylesObject[key + "#" + data._id]) {
          return this.stylesObject[key + "#" + data._id];
        } else {
          let field = this.allFieldsObj[key];
          let fieldKey =
            field.key && field.key.includes("#")
              ? field.key.split("#")[1]
              : field.key;
          let styles = this.getFieldStyle(
            {
              id: fieldKey,
              input_type: field.input_type,
              key: fieldKey,
              label: field.label,
              template_id: field.template_id,
              type: field.input_type,
            },
            data
          );
          this.$set(this.stylesObject, key + "#" + data._id, styles);
          return styles;
        }
      }
      return "";
    },

    prepareEntityStructure() {
      let preparedData = {};
      if (typeof this.currentData === "object" && this.currentData !== null) {
        Object.keys(this.currentData).forEach((key) => {
          let data = this.currentData[key];
          if (key.includes("#")) {
            let hashRemove = key.split("#")[1];
            preparedData[hashRemove] = data;
          }
        });
      } else {
        console.error("currentData is not a valid object:", this.currentData);
      }
      return preparedData;
    },

    handleEntityField(field) {
      if (!this.currentData[field.key]) {
        this.currentData[field.key];
      }
    },
    filterData(row) {
      // const unsupportedTypes = ["DATA_TABLE"];
      const fields = [];
      this.currentData = row.entityData;
      this.showFields.multiselectDisplay.forEach((displayField) => {
        const field = this.allFieldsObj[displayField]; // Access the field based on the display order
        if (field && field.inputType !== "DATA_TABLE") {
          fields.push({
            ...field,
            value:
              row.entityData[field.key.split("#")[0]][field.key.split("#")[1]],
          });
        }
      });

      return fields;
    },
    isSelectedInBulk(templateId) {
      // Check if any object in selectedRowsIds has a matching _id
      return this.selectedRowsIds.some((item) => item._id === templateId);
    },
    selectAll() {
      // Check whether it's search mode or not and use the appropriate data source
      const data = this.searchQuery ? this.searchData : this.firstBlockData;
      if (this.selectedRowsIds.length === data.length) {
        this.selectedRowsIds = [];
      } else {
        // Otherwise, select all records
        this.selectedRowsIds = [...data];
      }
    },
    onCheckboxChange(record, event) {
      if (event) {
        // Add the ID to the array if it's not already selecte
        if (!this.selectedRowsIds.includes(record)) {
          this.selectedRowsIds.push(record);
        }
      } else {
        // Remove the ID from the array if it's unchecked
        const index = this.selectedRowsIds.indexOf(record);
        if (index !== -1) {
          this.selectedRowsIds.splice(index, 1);
        }
      }
    },
    getParsedData(dataId) {
      return (
        this.parsedData?.find((data) => data._id == dataId)?.ejsTemplate || ""
      );
    },
    getCurrentTemplateFields(tempId) {
      return this.currentEntity.templates.find((e) => e.template_id == tempId)
        ?.templateInfo?.sections[0]?.fields;
    },
    getActionButtonField(actionKey, dataId) {
      let field = this.allFieldsObj[actionKey];
      return {
        ...field,
        parent_entityDataId: dataId,
        parent_entityId: this.currentEntity._id,
        key: field.key.includes("#") ? field.key.split("#")[1] : field.key,
      };
    },
    refreshCurrentPage() {
      this.$emit("refreshPage");
    },
    doubleClickUpdate(row) {
      this.UpdatedData = JSON.parse(JSON.stringify(this.currentData));
      this.$emit("quickUpdateData", this.UpdatedData);
      this.$emit("updateRow", row);
    },
    cancelEditAll() {
      this.isCancelEditAll = false;
      this.isEdit = false;
    },
    fieldHashRemove(field) {
      let modifiedKey = field.key;
      let modifiedLabel = field.label;
      if (field.key.includes("#")) {
        modifiedKey = field.key.split("#")[1];
      } else if (field.key.includes("-")) {
        modifiedKey = field.key.split("-")[1];
      }
      if (field.label.includes("#")) {
        modifiedLabel = field.label.split("#")[1];
      } else if (field.label.includes("-")) {
        modifiedLabel = field.label.split("-")[1];
      }
      field.key = modifiedKey;
      field.label = modifiedLabel;
      return field;
    },
    getFieldForQuickUpdate(step) {
      return this.currentEntity?.templates
        ?.find((e) => e.template_id == step.template_id)
        .templateInfo.sections[0]?.fields.find((e) => e.key == step.id);
    },
    cellDoubleClicked(row) {
      this.isEdit = true;
      this.isCancelEditAll = true;
      this.currentData = JSON.parse(JSON.stringify(row.entityData));
      if (
        this.currentEntity?.templates.length !== Object.keys(row.entityData)
      ) {
        this.currentEntity?.templates.map((tmp) => {
          if (!Object.keys(this.currentData).includes(tmp.template_id)) {
            let emptyTemplateDataObj = {};
            tmp.templateInfo.sections[0].fields.map((fd) => {
              emptyTemplateDataObj[fd.key] = "";
            });
            this.currentData[tmp.template_id] = emptyTemplateDataObj;
          }
        });
      }
    },
    hasHash(value) {
      return value.includes("#");
    },
    async viewWorkUserData(row, action = "View") {
      if (row._id) {
        let path = "";

        if (action === "View" || action === "Edit") {
          path = await this.determinePath(action);
        } else {
          this.performAnotherAction(action, row);
          return;
        }
        if (path) {
          let query = this.getNavigationQuery(this.$route.query);
          query = {
            ...query,
            dataId: row._id,
            filter: this.currentFilter,
            menu_id: this.$route.params.menuId,
          };
          if (action === "Edit" && this.isApplicationUserSide) {
            query.layout = "viewType";
            query.page = this.$route.query.page || 1;
            query.pageSize = this.$route.query.pageSize || 10;
            query.type = "AUfYWb7SC6U=";
          }
          if (action === "View" && this.isApplicationUserSide) {
            query.layout = "viewType";
            query.page = this.$route.query.page || 1;
            query.type = "AUfYWb7SC6U=";
          }
          this.$router.push({
            path: path,
            query: query,
          });
        }
      }
    },
    async performAnotherAction(action, row) {
      const matchingAction = this.customization?.actions.find(
        (item) => item.label === action
      );
      if (matchingAction) {
        this.quickActionDummyField = {
          label: matchingAction.label,
          parent_entityId: this.currentEntity._id,
          parent_entityDataId: row._id,
          action_button_type: "QUICK_ACTION",
          actions: [
            {
              action_button_action_type: "ESTABLISH_RELATIONSHIP",
              relational_entity_one: matchingAction.relationship,
              action_button_relationship_type:
                this.quickActionButtonType[matchingAction.type],
            },
          ],
          type: matchingAction.type,
          execute: true,
        };
        this.quickActionModal = true;
      }
    },
    async determinePath(action) {
      let path = "";
      if (action === "View") {
        if (this.isApplicationUserSideValid) {
          path = `/ap/entity-view/${this.entity_id}`;
        } else {
          path =
            this.getIframeUrlInfo != null
              ? `/if/${this.getIframeUrlInfo._id}/entity/view/${this.entity_id}`
              : `/entity/view/${this.entity_id}`;
        }
      } else if (action === "Edit") {
        if (this.isApplicationUserSideValid) {
          path = `/ap/entity-execute/${this.entity_id}`;
        } else {
          path =
            this.getIframeUrlInfo != null
              ? `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`
              : `/entity/edit/${this.entity_id}`;
        }
      }
      return path;
    },
    getFieldKey(field) {
      if (!this.keyLabelData || !Object.keys(this.keyLabelData).length) {
        console.warn("keyLabelData is undefined or empty");
        return null;
      }

      let firstObjectKey = Object.keys(this.keyLabelData)[0];
      if (this.showFields[field]) {
        if (Array.isArray(this.showFields[field])) {
          return this.showFields[field]
            .map((item) => {
              let [, key] = item.split("#");
              if (this.keyLabelData[firstObjectKey][key]) {
                return this.keyLabelData[firstObjectKey][key];
              }
              return null;
            })
            .filter(Boolean)
            .join(", ");
        } else if (typeof this.showFields[field] === "string") {
          let [, key] = this.showFields[field].split("#");
          if (this.keyLabelData[firstObjectKey][key]) {
            return this.keyLabelData[firstObjectKey][key];
          }
        }
      }
      return null;
    },
    getFieldValue(field, data) {
      if (!data || !data.entityData) {
        return "";
      }
      let firstObject = Object.keys(data.entityData)[0];
      if (!firstObject) {
        return "";
      }
      if (
        "date_time" in (data.entityData[firstObject] || {}) &&
        this.showFields[field]?.split("#")[1] === "date_time" &&
        data.entityData[firstObject]["date_time"]
      ) {
        const formattedDate = this.formatDate(
          data.entityData[firstObject]["date_time"]
        );
        return formattedDate;
      }
      if (field === "action_group_button") {
        let quickActions = [];
        if (this.showFields[field]?.length > 0) {
          quickActions = this.showFields[field].filter(
            (item) => !item.includes("#")
          );
          if (quickActions.length > 0) {
            return quickActions.map((item) => item);
          }
          return "";
        }
      } else if (
        data.entityData &&
        this.showFields[field] &&
        this.showFields[field].includes("#")
      ) {
        let [temp, key] = this.showFields[field]?.split("#") || [];
        if (temp && key) {
          if (data.entityData[temp] && data.entityData[temp][key]) {
            let value =
              data.entityData[temp][key + "/name"] ||
              data.entityData[temp][key];
            return value;
          }
        }
        return "";
      }

      // Handle profile fields
      if (this.currentEntity === "INDIVIDUAL") {
        if (
          field === "profile" &&
          data.avatar &&
          this.showFields[field] === "defaultpic"
        ) {
          return data.avatar;
        }
      }

      // Default return value
      return "";
    },
    handleStyles() {
      if (this.showFields?.columnsPerRow) {
        switch (this.showFields.columnsPerRow) {
          case 1:
            this.cardWidth = 100;
            this.cardHeight = 600;
            break;
          case 2:
            this.cardWidth = 45;
            this.cardHeight = 400;
            break;
          case 3:
            this.cardWidth = 32;
            this.cardHeight = 300;
            break;
        }
      }
    },
    selectedCard(dt) {
      this.selectedRecord = dt;
    },
    checkEmailTemplateExists() {
      return this.selectedEmailTemplate;
    },
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "getEntityShowFields",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    processedFields() {
      return this.showfields.multiselectDisplay.map(
        (item) => item.split("#")[1]
      );
    },
    filteredSelectedFields() {
      const filteredFields = (this.showFields.multiselectDisplay || []).map(
        (field) => {
          const result = field.split("#")[1];
          return result;
        }
      );
      return filteredFields;
    },
    firstBlockData() {
      return this.parsedData;
    },
    searchFilterData() {
      if (this.searchQuery !== "") {
        if (this.selectedRecord) {
          return this.searchData.filter(
            (row) => row._id === this.selectedRecord._id
          );
        } else {
          return this.searchData;
        }
      }
      return [];
    },

    middleDivFilterData() {
      if (this.selectedRecord) {
        return this.parsedData.filter(
          (row) => row._id === this.selectedRecord._id
        );
      }
      return this.parsedData;
    },
  },
  watch: {
    "showFields.multiselectDisplay": {
      handler(newVal) {
        this.showFields.multiselectDisplay = newVal;
      },
      deep: true,
    },
    
    data(newData) {
      if (newData && newData.length > 0) {
        this.selectedRecord = newData[0];
        this.currentData = JSON.parse(JSON.stringify(this.selectedRecord));
      }
    },
    parsedData: {
      handler(newVal) {
        this.parsedData = newVal;
      },
      deep: true,
    },
    selectedRecord() {
      return this.parsedData.length > 0 ? this.parsedData[0] : null;
    },
    paginatedData() {
      if (this.searchData.length > 0) {
        this.selectedRecord = this.searchData[0];
        return this.searchData;
      }
    },
    showFields: {
      handler: function () {
        if (
          JSON.stringify(this.showFields) != JSON.stringify(this.showFields)
        ) {
          this.showFields = { ...this.showFields };
        }
      },
      deep: true,
    },
    "showFields.columnsPerRow": {
      async handler() {
        await this.handleStyles();
      },
      deep: true,
    },
    "showFields.selected_email_template": {
      async handler() {
        this.selectedEmailTemplate = this.showFields.selected_email_template;
      },
      deep: true,
    },
    isRelationalDataFetched: {
      handler: function () {
        this.selectedRecord = null;
        this.selectedRecord = this.parsedData?.[0];
        // console.log("isRelationalDataFetched", this.isRelationalDataFetched);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.icon-container {
  position: relative;
  display: inline-block;
  margin-top:5px;
}
.draggable-icon{
height:10px;
width:10px;
margin-right: 2px;

}

.value-field{
 padding-top: 4px;
 margin-top:5px;
}
.key-field{
max-width: 40%;
padding-right: 8px; 
flex: 0 0 40%;
word-break: break-word;
 margin-top:5px;
}
.no-edit{
display:flex;
 margin-left: 10px;
 
}
.img-active{ 
  display:none;
  margin-right: 4px;
  margin-left: 4px;
  font-weight: 600;
  height:15px;
  margin-top: 2px;
}
.img-active:hover {
  display:block;
}
.empty {
  margin-left: 80px;
}
.second-card {
  display: flex;
  padding: 7px;
  min-width:80%
}
.second-card-key {
  width: 50%;
}
.edit-cancel-div {
  margin-bottom: 33px;
}
.multiselect-style {
  margin-bottom: 10px;
}
.card-description {
  display: flex;
}
.bold-key {
  font-weight: 700;
}
.edit-input {
  width: 80%;
  margin-right: 5px;
  padding: 8px;
  border: 1px solid #dcdfe6;
  color: #606266;
  margin-left: 4px;
  margin-top: 2px;
}
.edit-icons {
  display: inline-block;
}

.tick-icon {
  color: green;
  cursor: pointer;
  margin-right: 5px;
}

.cross-icon {
  color: red;
  cursor: pointer;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 16px;
  width: 46.5vw;
  overflow-x: hidden;
}
.card-body {
  border-radius: 10px;
  background: #ffff;
  overflow-wrap: break-word;
  margin-right: 20px;
}
.card-profile {
  margin-left: 60px;
  margin-top: 10px;
  /* margin-right:60px ; */
}
.content {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.size-selector {
  margin-bottom: 16px;
}

.size-selector label {
  font-weight: bold;
  margin-right: 8px;
}

.size-selector input {
  margin-right: 8px;
}
.entity-data-card {
  background: #ffffff;
  border-radius: 10px;
  min-height: 280px;
  border: 1px solid slategray;
  margin-left: 10px;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .card-container {
    justify-content: center;
  }

  .card {
    flex: 0 1 100%;
  }
}

@media (max-width: 1200px) {
  .card {
    flex: 0 1 48%;
  }
}

@media (min-width: 1201px) {
  .card {
    flex: 0 1 30%;
  }
}

.card-summary {
  max-height: 10vh;
  cursor: pointer;
}

.summary {
  min-width: 5vw;
  min-height: 10vh;
  overflow-y: auto;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #dcdfe6;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  display: flex;
}
.summary:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1); /* Professional, subtle shadow */
  transform: translateY(-3px); /* Optional: A slight lift effect */
}
.card-details {
  max-height: 30vh;
  width: 15vw;
}
.edit-icons {
  align-content: center;
  align-items: center;
  float: right;
  margin: 5px;
}
.details {
  max-height: 40vh;
  overflow-y: hidden;
  background-color: ffffff;
  border: 1px slategray solid;
  border-radius: 5px;
  margin-bottom: 5px;
}
.button-container {
  display: flex;
  justify-content: end;
}
</style>
